/* Base theme */

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  box-shadow: inset 0 0 10px 10px #79a3ff;
  border: 3px transparent solid;
}

::-webkit-scrollbar-corner {background-color: transparent;}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;

  &.react-datepicker__day--in-range {
    background-color: #216ba5;
    color: #fff;
  }
}

#root {
  display: flex;
  overflow: hidden;
  height: 100%;

  & > .content {
    flex: 1;
    overflow: auto;
  }
}

.loader {
  position: relative;

  & .title{
    font-size: 22.0;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
  }

  & .progress-loader {
    border: 16px solid #2F3B52;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    border-top: 16px solid #f3f3f3;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

.error-toast .Toastify__toast-body {
  min-width: 80%;
}

.menu {
  width: 80px;
  background-color: #1a202e;
  color: #657795;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  & .logo {
    height: 60px;
    display: block;
    background: #2f4ff1;
    background-repeat: no-repeat;
    // cursor: pointer;
    /* background-image: url(../images/logo-mini-menu-white.svg);
    background-image: url(../images/logo-mini-menu-white.svg), linear-gradient(90deg, #2F4FF1 0%, #4183F9 100%); */
    background-image: linear-gradient(90deg, #2f4ff1 0%, #4183f9 100%);
  }

  &.open {
    width: 235px;

    & .item {
      & > b {
        opacity: 1;
      }

      & span {
        opacity: 1;
      }

      & .actions {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);

        & .ellipsis {
          padding: 15px 10px;
          cursor: pointer;

          & b,
          & b:before,
          & b:after {
            background-color: #fff;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            display: block;
          }

          & b {
            &:before,
            &:after {
              content: "";
              position: absolute;
            }
            &:before {
              top: 6px;
            }
            &:after {
              bottom: 6px;
            }
          }
        }
      }

      & .facility-tooltip {
        & a {
          text-decoration: none;
          color: #2f4ff1;
        }
      }
    }
  }

  & .logo-2 {
    height: 60px;
    // cursor: pointer;
    opacity: 1;
    transition-property: opacity 0.3s ease-in-out;

    display: flex;
    align-items: center;
    padding-left: 19px;

    position: relative;

    & img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }

    & .company-name {
      max-width: 149px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
      white-space: nowrap;

      & a span {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
      }
    }

    & a {
      text-decoration: none;
    }

    & .actions {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);

      & .ellipsis {
        padding: 15px 10px;
        cursor: pointer;

        & b,
        & b:before,
        & b:after {
          background-color: #fff;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: block;
        }

        & b {
          &:before,
          &:after {
            content: "";
            position: absolute;
          }
          &:before {
            top: 6px;
          }
          &:after {
            bottom: 6px;
          }
        }
      }
    }

    & .margin-bottom {
      margin-bottom: 5px;
    }

    & .change-company {
      color: #2f4ff1;
      cursor: pointer;
    }
  }

  & .content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    & .submenu {
      color: #98acc1;
      width: 235px;
      box-sizing: border-box;
      overflow: hidden;
      transition: all 0.3s ease-out;
      background-color: transparent;

      & > div {
        &.submenu-item {
          height: 28px;
          position: relative;
          font-size: 14px;
          padding: 5px 20px 5px 40px;
          cursor: pointer;

          & > a span {
            color: #657795;
            line-height: 14px;
            position: absolute;
            left: 40px;
            right: 20px;
            transform: translateY(-50%);
            top: 50%;
            transition: color 0.2s;
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            background-color: #98acc1;
            width: 6px;
            height: 6px;
            top: 50%;
            left: 31px;
            border-radius: 6px;
            transform: translate(-50%, -50%);
            transition: background-color 0.2s;
          }

          &:hover {
            &:before {
              background-color: #fff;
            }
          }

          &.selected:hover:before,
          &.selected:before {
            background-color: #f5a623;
          }

          &:hover,
          &.selected {
            color: #fff;
          }

          &:first-child {
            margin-top: 5px;
          }

          &:last-child {
            margin-bottom: 5px;
          }
        }

        &.submenu-content {
          & > div {
            height: 28px;
            position: relative;
            font-size: 12px;
            padding: 5px 20px 5px 40px;
            cursor: pointer;
            transition: color 0.2s;

            &:before {
              position: absolute;
              z-index: 0;
              background-color: #263043;
              content: "";
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              transform: translateX(-100%);
              transition: transform 0.2s ease-in-out;
              transition-property: background-color transform;
            }

            &.selected {
              color: #fff;

              &:before {
                transform: translate(0);
              }
            }

            & > span {
              line-height: 14px;
              position: absolute;
              left: 50px;
              right: 20px;
              transform: translateY(-50%);
              top: 50%;
              transition: color 0.2s;
            }
          }
        }
      }
    }
  }

  & .item {
    line-height: 60px;
    min-height: 60px;
    position: relative;
    color: #657795;
    fill: #657795;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;

    &:hover,
    &.selected {
      color: #fff;
      fill: #fff;
    }

    & > a img {
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translate(-50%, -50%);
      height: 20px;

      &.user-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }

    & .avatar-container {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.98);
      transform: translate(-50%, -50%);
      margin-left: 41px;
      margin-top: 25px;

      & img {
        // height: 30px;
        height: 22px;
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:before {
      position: absolute;
      z-index: 0;
      background-color: rgba(101, 119, 149, 0.2);
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform 0.2s ease-in-out;
      transition-property: background-color transform;
    }

    &:hover:before,
    &.selected:before {
      transform: translateX(0%);
    }

    &.selected {
      &:before {
        background-color: #657795;
      }

      &:after {
        height: 100%;
      }
    }

    &:after {
      content: "";
      background-color: #79a3ff;
      display: block;
      width: 4px;
      height: 0%;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      transition: height 0.2s ease-in-out;
    }

    & > b {
      width: 15px;
      height: 60px;
      position: absolute;
      top: 0px;
      right: 5px;
      transform: rotate(0deg);
      transition: transform 0.2s ease-out;
      transition-property: transform opacity;
      opacity: 0;

      & > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 20px;
      }
    }

    &.closed {
      & > b {
        transform: rotate(-180deg);
      }
    }

    & a span {
      color: #657795;
      position: absolute;
      left: 65px;
      // width: 150px;
      line-height: 25px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      font-size: 18px;
      display: block;
    }
  }

  &.item.selected + & .content .submenu {
    background-color: #2f3b52;
  }

  &.content.submenu > div.submenu-item + div.submenu-content {
    display: block;
  }

  &.closed .item + .submenu,
  & .item.closed + .submenu {
    height: 0 !important;
  }
}

.view {
  padding: 60px 50px 60px 50px;
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 100%) no-repeat;
  background-size: auto 470px;
  min-height: 90%;

  & > .title {
    height: 80px;
    width: 784px;
    color: #ffffff;
    font-weight: bolder;
    font-size: 36px;
    line-height: 55px;
  }
}

.dialog {
  .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;

    & .content {
      position: absolute;
      top: 100px;
      height: auto;
      left: 50%;
      margin-right: auto;
      // border: 1px solid rgb(204, 204, 204);
      background: rgb(255, 255, 255);
      background-image: url("/images/confirm-dialog-decoration.png");
      background-repeat: no-repeat;
      background-position-x: right;
      overflow: auto;
      outline: none;
      border-radius: 12px;

      & .title {
        margin-top: 55px;
        margin-left: 50px;
        color: #20293d;
        font-size: 36px;
        font-weight: 800;
        line-height: 55px;
      }

      & .close {
        background: url("/images/close.svg") no-repeat;
        position: absolute;
        top: 32px;
        right: 32px;
        cursor: pointer;
        width: 32px;
        height: 32px;
      }

      & .message {
        margin-top: 35px;
        margin-left: 50px;
        margin-bottom: 110px;
        margin-right: 50px;
        color: #20293d;
        line-height: 25px;
      }

      & .actions {
        position: relative;
        bottom: 0px;
        right: 0px;
        margin-top: 30px;
        margin-bottom: 40px;
        margin-right: 60px;
        margin-left: 50px;
        text-align: right;
      }

      &.conflict-modal {
        width: 75%;
        margin-left: 0;
        transform: translateX(-50%);
        top: 5%;

        & .message {
          margin-top: 0px;
          margin-bottom: 0px;

          & .header {
            display: flex;
            margin-bottom: 30px;
            font-size: 16px;
            & :first-child {
              width: 22%;
              font-weight: bold;
            }
          }

          & .conflict-table {
            & .table-header {
              display: flex;
              color: #898A8D;
              font-size: 12px;
              font-weight: bold;
              border-bottom: 1px solid #979797;
            }

            & .table-body {
              max-height: 300px;
              overflow: auto;

              & .conflict-row {
                display: flex;

                & > div {
                  padding-top: 5px;
                }

                & .plates {
                  font-size: 12px;
                }

                & .conflict-bar {
                  position: absolute;
                  height: calc(100% - 5px);
                  background: #F9DDDD;
                  border-left: 2px solid #AC0000;
                  border-left: 5px solid #AC0000;
                  box-sizing: border-box;
                  border-radius: 5px;
                }

                & .table-actions {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                & .btn {
                  min-width: fit-content;
                  width: fit-content;
                }
              }
            } 

            & .orders {
              width: 11%;
            }

            & .truck {
              width: 11%;
            }

            & .timeline {
              width: 67%;
              position: relative;
              border-left: 1px solid #979797;
              border-right: 1px solid #979797;

              & .time-begin {
                position: absolute;
                left: 0;
              }

              & .time-end {
                position: absolute;
                right: 0;
                transform: translateX(105%);
              }
            }

            & .table-actions {
              width: 11%;
            }
          }
        }

        & .actions {
          justify-content: end;
        }
      }

      &.delete-tags,
      &.invite-operator {
        width: 700px;
        margin-left: -350px;

        & .message {
          margin-bottom: 30px;

          & .formDivided {
            display: inline-flex;
            width: 100%;
          }
        }

        & .actions {
          & .outline {
            color: #4183f9;
          }
        }
      }
      &.transfer-events{
        width: 700px;
        margin-left: -350px;

        & .message {
          margin-top: 0px;
          margin-bottom: 30px;

          & .formDivided {
            display: inline-flex;
            width: 100%;
          }

          & .data-key {
            color: rgba(32,41,61,1);
            font-size: 14;
            font-weight: 700;
            opacity: 0.8;
          }

          & .data-value {
            color: rgba(32,41,61,1);
            font-size: 14;
            font-weight: 700;
          }

        }

        & .actions {
          & .outline {
            color: #4183f9;
          }
        }
      }

      &.delete-tags {
        & .tag {
          line-height: 18px;
        }
      }

      &.invite-operator {
        & .license-search {
          display: flex;
          align-content: center;

          & .text-input {
            width: 60%;
            margin-right: 10%;
          }

          button {
            position: relative;
            top: 30px;

            &.outline {
              color: #4183f9;
            }
          }
        }

        & .result-message {
          font-size: 12px;
          font-weight: bold;
          margin: 0px;
          transform: translateY(-15px);

          &.failure {
            color: #fb8a8a;
          }

          &.success {
            color: #5fa810;
          }
        }

        & .create-operator {
          display: flex;
          margin-top: 20px;
          font-size: 14px;

          & div:first-child {
            width: 60%;
            margin-right: 11%;
          }
        }

        & .chip-container {
          & .title {
            margin: 0;
            font-size: 20px;
          }
        }
      }
    }
  }

  &.generic-dialog {
    & .content {
      width: 600px;
      margin-left: -300px;

      & .title {
        color: #20293d;
        line-height: 60px;
        font-size: 30px;
        font-weight: bold;
        margin-left: 60px;
      }

      & .message {
        margin-top: 40px;
        margin-left: 60px;
        margin-right: 75px;
        margin-bottom: 30px;
        font-size: 20px;
      }

      & .bottom-container {
        margin-left: 60px;
        margin-right: 75px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &.password-dialog {
    & .content {
      width: 650px;
      margin-left: -325px;
      background-image: url("/images/reset-password-modal.png");

      & .title {
        color: #20293d;
        line-height: 60px;
        font-size: 30px;
        font-weight: bold;
        margin-left: 60px;
      }

      & .message {
        margin-top: 300px;
        margin-left: 60px;
        margin-right: 75px;
        margin-bottom: 20px;
        font-size: 20px;
      }

      & .bottom-container {
        margin-left: 60px;
        margin-right: 75px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &.login-dialog {
    & .content {
      top: 2em;
      max-width: 700px;
      // margin-left: -350px;
      width: 90%;
      transform: translateX(-50%);
      overflow: visible;

      & .title {
        color: #20293d;
        line-height: 60px;
        font-size: 30px;
        font-weight: bold;
        margin-left: 60px;
      }

      .checklist-tooltip {
        font-size: 1em;
      }

      & .show {
        max-width: 215px;
      }

      & .form {
        // margin-left: 60px;
        // margin-right: 75px;    
        margin-left: 1em;
        margin-right: 1em;

        & .text-input {
          margin-bottom: 10px;
        }

        & .text-input-invalid {
          margin: -8px 0px 0px 0px;
          & .p-error {
            font-size: .6em;
          }
        }

        & .button-container {
          margin-top: 20px;
          margin-bottom: 70px;
          width: 100%;
          display: flex;
          // justify-content: space-between;
          justify-content: space-around;

          & .btn {
            font-size: 1em;
            white-space: nowrap;
          }

          & .recover-password {
            margin-top: 50px;

            & .btn {
              width: 250px;
            }
          }

          & .terms-conditions-container {
            display: flex;
            width: 50%;
            font-size: 14px;

            & .link {
              color: #4184f9;
              cursor: pointer;
            }

            & .checkbox-container {
              margin-top: 12px;
            }
          }
        }

        & .no-account {
          margin-top: 35px;
        }

        & .options-container {
          display: flex;
          // justify-content: space-between;
          justify-content: space-around;

          & .forgot-link {
            margin-top: 35px;
            cursor: pointer;
          }
        }
      }

      & .textNewAccount {
        cursor: pointer;
      }
    }
  }

  &.confirm-dialog {
    & .content {
      width: 500px;
      margin-left: -250px;
    }

    & .actions {
      display: flex;
      justify-content: space-evenly;
    }

    & .message {
      position: relative;

      & .progress-loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
      }
      
      /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }

  &.base-dialog {
    & .content {
      background-image: none;
      width: 700px;
      margin-left: -350px;
      & .title {
        margin-top: 23px;
        margin-left: 20px;
        color: #20293d;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 37px;
      }
      & .close {
        filter: invert(100%);
        width: 20px;
        height: 20px;
        right: 20px;
        top: 20px;
        background-size: cover;
      }
      & .message {
        margin-top: 5px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;

        color: #20293d;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }

    & .actions {
      display: flex;
      justify-content: space-evenly;
    }
  }

  & .balance-modal-content {
    font-size: 16px;
    & .tripTable {
      margin: 20px 5% 20px 5%;
      line-height: 40px;
      border-bottom: 2px solid #a4b4ce80;
      text-align: center;
      font-weight: 700;
      & .tripTable-header {
        display: flex;
        background-color: #98acc126;
        font-size: 14px;
        & div {
          width: 50%;
        }
      }

      & .tripTable-body {
        & .tripTable-row {
          display: flex;
          & div {
            width: 50%;
          }
        }
      }
    }
  }

  &.select-option-dialog {
    & .content {
      width: 500px;
      margin-left: -250px;
    }

    & .actions {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &.avatar-select-dialog {
    & .content {
      width: 700px;
      margin-left: -350px;

      & .message {
        margin-bottom: 40px;
      }

      & .actions {
        margin-top: 120px;
      }

      & .wrapper {
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        // margin-left: 68px;
        justify-content: center;
        height: fit-content;

        & .item-container {
          cursor: pointer;
          border: 6px solid #fff;

          & .img-background {
            width: 120px;
            height: 120px;
            // background-color: #3d77f7;
            background: linear-gradient(180deg, #3664f4 0%, #3c99f7 100%);
            margin: 8px;
            border-radius: 80px;
            text-align: center;

            & img {
              width: 80px;
              height: 80px;
              position: relative;
              top: 15px;
            }
          }

          &.active {
            border: 6px solid #3d77f7;
            border-radius: 6px;
          }
        }
      }
    }
  }

  &.invitation-dialog {
    & .content {
      width: 700px;
      margin-left: -350px;
      background-image: url("/images/sent-email-decoration.png");

      & .title {
        margin: 60px 60px 0 60px;
      }

      & .message {
        margin-top: 230px;
        margin-left: 50px;
        margin-right: 60px;
        margin-bottom: 10px;
        color: #20293d;
        line-height: 25px;
        text-align: center;
      }

      & .button_container {
        position: relative;
        bottom: 0px;
        right: 0px;
        margin-top: 30px;
        margin-bottom: 40px;
        margin-left: 500px;
        text-align: right;
      }
    }
  }

  &.add-assemble-modal {
    & .content {
      overflow: visible;
      width: 1000px !important;

      & .message {
        margin-top: 0px;

        & .subtitle {
          margin-top: 30px;
          background-color: #98acc11c;
          color: #252325;
          font-size: 20px;
          font-weight: bold;
          height: 53px;
          display: flex;
          align-items: center;
          padding-left: 20px;
          border-bottom: 2px solid #979797;
          justify-content: space-between;
          padding-right: 10px;

          & .text-search {
            transform: translateY(-10px);
            & .text-input {
              margin: 0;
            }
          }
        }

        & .assemblies-table {
          & .assemblies-table-header {
            display: flex;
            height: 53px;
            background-color: #98acc140;
            color: #252325;
            font-size: 14px;
            font-weight: bold;
            padding-left: 47px;

            & .operator {
              width: 27%;
              line-height: 53px;
            }
            & .status {
              width: 19%;
              line-height: 53px;
            }
            & .equipment {
              width: 27%;
              line-height: 53px;
            }
            & .vehicle {
              width: 27%;
              line-height: 53px;
            }
          }

          & .assemblies-table-body {
            max-height: 167px;
            overflow-y: auto;
            border-bottom: 1px solid #979797;

            & .assemblies-table-row {
              height: 40px;

              & .radio-container {
                margin: 6px 12px 6px 12px;
                padding-right: 0px;

                &.busy {
                  cursor: default;
                }
              }

              & .assembly-label {
                display: flex;
                color: #20293d;
                font-size: 14px;
                letter-spacing: 0.29px;
                & > div {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                & .operator {
                  width: 27%;
                  & span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                & .status {
                  width: 19%;
                  // text-align: center;
                  padding-left: 20px;
                  box-sizing: border-box;

                  &.busy {
                    color: #fd3838;
                  }
                }
                & .equipment {
                  width: 27%;
                  & span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                & .vehicle {
                  width: 27%;
                  & span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }

        & .date {
          display: flex;
          align-items: baseline;

          & .text-input {
            width: 40%;
            margin-bottom: 0px;

            & .label {
              color: #20293d;
            }

            &.has-input,
            &:focus-within {
              & > label {
                color: #9ba1ac;
              }
            }
          }

          & .checkbox-container {
            margin-left: 20px;
          }
        }
      }
    }
  }

  &.create-zone-detail-modal {
    & .content {
      width: 800px;
      transform: translateX(-50%);
      top: 50px;
      
      & .message {
        margin-bottom: 35px;
        margin-top: 10px;

        & .name-input {
          width: 50%;
        }

        & .zone-details {
          max-height: 300px;
          min-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;

          & .hour-input {
            display: flex;    
            align-items: baseline;

            & .select-input {
              width: 15%;
              padding: 0;
              margin: 0 10px;

              & select {
                padding: 0;
              }

              & .caret {
                top: 40%;
              }
            }
          }

          & .bottom-inputs {
            display: flex;
          }

          & .class-input {
            width: 40%;

            & .caret {
              top: 50%;
            }

            & .select-content {
              box-shadow: 3px 7px 16px 4px #00000033;
            }
          }

          & .day-input {
            width: 55%;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
            padding-left: 3%;

            & .day-picker {
              height: 25px;
              width: 25px;
              color: #79A3FF;
              border: 1px solid;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              line-height: 28px;
              cursor: pointer;
              
              &.selected {
                color: #fff;
                background-color: #79A3FF;
                border-color: #79A3FF;
              }
            }
          }

          & .delete {
            width: 5%;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;

            & img {
              height: 25px;
              cursor: pointer;
            }
          }

          & .detail {
            padding: 20px;
            border-bottom: 1px solid #34343473;
          }
        }

        & .add-button {
          color: #0077E2;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 25px;
          cursor: pointer;
        }
      }

      & .actions {
        display: flex;
        justify-content: space-evenly;

        & .outline {
          color: #4183f9;
        }
      }
    }
  }

  &.create-zone-modal {
    & .content {
      transform: translateX(-50%);
      width: 500px;

      & .message {
        margin-bottom: 35px;

        & .radio-container {
          font-size: 16px;
          letter-spacing: 0.33px;
          line-height: 29px;
          display: block;
          position: relative;
          margin: 0 12px 12px 12px;
          cursor: pointer;
          padding: 0px 35px;
          font-weight: bold;

          & input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
              background-color: #2196f3;

              &:after {
                display: block;
              }
            }
          }

          & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #ccc;
            border-radius: 50%;
            transition: 0.3s;

            &:after {
              content: " ";
              position: absolute;
              top: 9px;
              left: 9px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: white;
              display: none;
            }
          }
        }
      }

      & .actions {
        display: flex;
        justify-content: space-evenly;

        & .outline {
          color: #4183f9;
        }
      }
    }
  }

  &.email-dialog {
    & .content {
      width: 700px;
      margin-left: -350px;
      background-image: url("/images/sent-email-decoration.png");

      & .custom-tooltip {
        background-color: rgb(40, 52, 71);
      }

      & .title {
        margin: 60px 60px 0 60px;
      }

      & .message {
        margin-top: 230px;
        margin-left: 50px;
        margin-right: 60px;
        margin-bottom: 10px;
        color: #20293d;
        line-height: 25px;
        text-align: center;
      }

      & .button_container {
        position: relative;
        bottom: 0px;
        right: 0px;
        margin-top: 30px;
        margin-bottom: 40px;
        margin-left: 500px;
        text-align: right;
      }
    }
  }

  &.generic-dialog-image {
    & .content {
      width: 700px;
      margin-left: -350px;
      text-align: center;
    }
  }

  &.error-dialog {
    & .content {
      width: 700px;
      margin-left: -350px;
      text-align: center;

      & .title {
        width: auto;
        height: 160px;
        background: url("/images/access-error.png") no-repeat center;
        margin: 60px 60px 0 60px;
        text-align: left;
      }

      & .message {
        margin-top: 35px;
        margin-left: 50px;
        margin-right: 60px;
        color: #20293d;
        line-height: 25px;
      }
    }
  }

  &.add-product-requisition-delivery-dialog {

    & .content {
      width: 850px;
      margin-left: -425px;
      text-align: center;
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;

      & .title-action {
        width: auto;
        text-align: left;
        font-size: 26px;
        font-weight: 800;
      }

      & .subtitle {
        width: auto;
        text-align: left;
        font-size: 18px;
      }

      & .header-container {
        margin-top: 30px;
        font-size: 15px;
        font-weight: 600;
        margin-right: 15px;

        & .header-row {
          display: flex;
          margin-top: 10px;

          & .header-item {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          }
        }

      }

      & .products-content {
        height: 300px;
        width: auto;
        overflow: hidden; 
        overflow-y: scroll;
        font-size: 15px;

        & .new-product-button {
          display: flex;
          cursor: pointer;
          color: #2f4ff1;
          align-items: center;
          height: 50px;
          width: fit-content;

          & .add-icon {
            display: inline-block;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            text-align: center;
            line-height: 24px;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
            margin-right: 8px;
          }
        }

        & .new-product-container {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;

          & .product-item {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 20px;
          }
        }

        & .product-container {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgb(164, 180, 206);

          & .product-row-2 {
            display: flex;
            width: 100%;
            margin-bottom: 12px;

            & .product-item {
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
              height: 20px;
            }
          }

          &.background-1 {
            background-color: rgb(255, 255, 255);
          }

          &.background-2 {
            background-color: rgb(252, 252, 252);
          }

          &:hover {
            background-color: rgb(229, 241, 251);
          }
        }

      }

      & .text-input-custom {
        font-size: 14px;
        width: 50%;
        border: none;
        border-bottom: 2px solid #c0cbdd;

        &:focus {
          outline: none;
        }
      }

      & input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      

    }

  }

  &.add-user-dialog {
    & .content {
      width: 800px;
      margin-left: -400px;
      text-align: center;
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;
      font-size: 15px;
      height: 80%;
      top: 10% !important;
      overflow: inherit !important;
      max-height: 510px;

      & .title-action {
        width: auto;
        text-align: left;
        font-size: 26px;
        font-weight: 800;
      }

      & .subtitle {
        width: auto;
        text-align: left;
        font-size: 18px;
      }

      & .search-component {
        display: flex;
        padding-left: 20%;
        padding-right: 20%;
        justify-content: center;
        align-items: center;

        & .icono {
          margin-top: 30px;
        }
      }

      & .users-section {
        margin-top: 20px;
        margin-bottom: 20px;

        & .users-list {
          overflow: hidden;
          overflow-y: auto;
          max-height: 280px;
        }

        & .user-item {
          height: 35px;
          display: flex;
          justify-content: start;
          align-items: center;
          border-bottom: 1px solid #c0cbdd;

          &:hover {
            background-color: rgb(229, 241, 251);
          }

          & .checkbox-container {
            height: 10px;
            position: relative;

            & .checkbox {
              position: absolute;
              top: 0px;
              right: 0px;
              height: 20px;
              width: 20px;
              border-radius: 4px;
              background-color: #a7b6d6;
              transition: 0.3s ease;
            }

            &.selected {
              & .checkbox {
                background-color: #3d77f7;
                &::after {
                  content: "";
                  height: 4px;
                  width: 10px;
                  border-bottom: 3px solid #fff;
                  border-left: 3px solid #fff;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -69%) rotate(-45deg);
                }
              }
            }
          }
          
        }

      }
    }
  }

  &.confirm-requisition-dialog {

    & .content {
      width: 900px;
      margin-left: -450px;
      text-align: center;
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;
      font-size: 15px;
      height: 80%;
      top: 10% !important;
      overflow: inherit !important;
      padding-right: 20px;
      max-height: 500px;

      & .title-action {
        width: auto;
        text-align: left;
        font-size: 26px;
        font-weight: 800;
      }

      & .subtitle {
        width: auto;
        text-align: left;
        font-size: 18px;
      }

      & .deliveries-content {
        margin-right: 20px;

        & .title-deliveries-content {
          margin-top: 20px;
          font-size: 17px;
          text-align: left;
        }

        & .delivery-row {
          display: flex;
          border-bottom: 1px solid #c0cbdd;
          padding-bottom: 8px;
          padding-top: 8px;
          font-size: 14px;

          &.with-hover {
            &:hover {
              background-color: rgb(229, 241, 251);
            }
          }

          & .delivery-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
          }

          & .checkbox-container-2 {
            position: relative;
            width: 20px;
            height: 20px;

            & .checkbox {
              position: absolute;
              top: 0px;
              right: 0px;
              height: 20px;
              width: 20px;
              border-radius: 4px;
              background-color: #a7b6d6;
              transition: 0.3s ease;
            }
  
            &.selected {
              & .checkbox {
                background-color: #3d77f7;
                &::after {
                  content: "";
                  height: 4px;
                  width: 10px;
                  border-bottom: 3px solid #fff;
                  border-left: 3px solid #fff;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -69%) rotate(-45deg);
                }
              }
            }
          }

        }

        & .delivery-list {
          overflow: hidden; 
          overflow-y: auto;
          height: 100%;
          font-size: 14px;

          & .delivery-row {
            display: flex;
            border-bottom: 1px solid #c0cbdd;
            padding-bottom: 8px;
            padding-top: 8px;
  
            & .delivery-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
  
          }
        }

      }

    }

  }

  &.convert-delivery-to-order-dialog {

    & .content {
      width: 900px;
      margin-left: -450px;
      text-align: center;
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;
      font-size: 15px;
      height: 80%;
      top: 10% !important;
      overflow: inherit !important;
      padding-right: 20px;
      max-height: 750px;

      & .title-action {
        width: auto;
        text-align: left;
        font-size: 26px;
        font-weight: 800;
      }

      & .consolidate-deliveries-content {
        margin-right: 20px;

        & .title-deliveries-content {
          margin-top: 20px;
          font-size: 17px;
          text-align: left;
        }

        & .delivery-row {
          display: flex;
          border-bottom: 1px solid #c0cbdd;
          padding-bottom: 8px;
          padding-top: 8px;
          font-size: 14px;

          &.with-hover {
            &:hover {
              background-color: rgb(229, 241, 251);
            }
          }

          & .delivery-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
          }

          & .checkbox-container {
            position: relative;

            & .checkbox {
              position: absolute;
              top: 0px;
              right: 0px;
              height: 20px;
              width: 20px;
              border-radius: 4px;
              background-color: #a7b6d6;
              transition: 0.3s ease;
            }
  
            &.selected {
              & .checkbox {
                background-color: #3d77f7;
                &::after {
                  content: "";
                  height: 4px;
                  width: 10px;
                  border-bottom: 3px solid #fff;
                  border-left: 3px solid #fff;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -69%) rotate(-45deg);
                }
              }
            }
          }

        }

        & .delivery-list {
          overflow: hidden; 
          overflow-y: auto;
          height: 100%;
          font-size: 14px;

          & .delivery-row {
            display: flex;
            border-bottom: 1px solid #c0cbdd;
            padding-bottom: 8px;
            padding-top: 8px;
  
            & .delivery-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
  
          }
        }

      }

      & .destinations-container {
        display: flex;
        margin-top: 20px;

        & .destination-item {
          width: 250px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

      }

      & .order-inputs-container {
        display: flex;

        & .input-item {
          flex: 1;

          & .text-container {
            display: flex;
            height: 30px;
            border-bottom: 2px solid #9ba1ac;
            cursor: pointer;

            & .text-value {
              display: flex;
              width: calc(100% - 22px);
              justify-content: start;
              align-items: center;
              color: #9ba1ac;
              font-size: 15px;
            }

            & .suffix-container {
              display: flex;
              width: 22px;
              justify-content: start;
              align-items: center;
            }

          }

        }

        & .input-tags {
          flex: 2;

          & .tag-title {
            font-size: 12px;
            line-height: 18px;
            color: rgba(155, 161, 172, 0.7);
          }
        }
      }

      & .deliveries-content {
        
        & .title-deliveries-content {
          margin-top: 20px;
          font-size: 17px;
          text-align: left;
        }

        & .delivery-row {
          display: flex;
          border-bottom: 1px solid #c0cbdd;
          padding-bottom: 8px;
          padding-top: 8px;
          font-size: 14px;

          & .delivery-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
          }

          &.with-hover {
            &:hover {
              background-color: rgb(229, 241, 251);
            }
          }

        }

        & .delivery-list {
          overflow: hidden; 
          overflow-y: auto;
          height: 80px;
          font-size: 14px;

          & .delivery-row {
            display: flex;
            border-bottom: 1px solid #c0cbdd;
            padding-bottom: 8px;
            padding-top: 8px;
  
            & .delivery-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
  
          }
        }

      }

      & .add-icon {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        text-align: center;
        line-height: 24px;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
        margin-right: 8px;
      }

    }
  }

  &.divide-requisition-delivery-dialog {

    & .content {
      width: 800px;
      margin-left: -400px;
      text-align: center;
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;
      font-size: 15px;

      & .title-action {
        width: auto;
        text-align: left;
        font-size: 26px;
        font-weight: 800;
      }

      & .subtitle {
        width: auto;
        text-align: left;
        font-size: 18px;
      }

      & .text-input-custom {
        font-size: 14px;
        width: 50%;
        border: none;
        border-bottom: 2px solid #c0cbdd;

        &:focus {
          outline: none;
        }
      }

      & input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      & .product-container {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 20px;

        & .product-row-2 {
          display: flex;
          width: 100%;
          margin-bottom: 12px;

          & .product-item {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 20px;
          }
        }
      }

      & .deliveries-list-content {
        height: 300px;
        width: auto;
        overflow: hidden; 
        overflow-y: scroll;
        font-size: 15px;

        & .delivery-item-wrap {
          background-color: transparent;
          border-bottom-color: gray;
          border-bottom-width: 1px;
          border-bottom-style: solid;

          & .delivery-item {
            display: flex;
            height: 50px;
            align-items: center;
            justify-content: space-between;
            background-color: transparent;

            & .collapser {
              cursor: pointer;
      
              & img {
                transform: rotate(180deg);
                transition: 0.4s ease;
              }
      
              &.collapsed img {
                transform: rotate(0deg) translateY(3px);
              }
            }
          }

          & .text-input-custom {
            font-size: 14px;
            width: 50%;
            border: none;
            border-bottom: 2px solid #c0cbdd;

            &:focus {
              outline: none;
            }
          }

          & input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          & .product-wrapper {
            display: flex;
          }

          & .product-header {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            font-weight: 600;
          }

          & .product-column-1 {
            display: flex;
            flex: 1.5;
            flex-direction: column;
            justify-content: center;
            height: 50px;
          }

          & .product-column-2 {
            display: flex;
            flex: 0.5;
            justify-content: center;
            align-items: center;
            height: 50px;
          }

          & .product-column-3 {
            display: flex;
            flex: 0.5;
            justify-content: center;
            align-items: center;
            height: 50px;
          }
        }
      }

      & .message {
        margin-top: 35px;
        margin-left: 50px;
        margin-right: 60px;
        color: #20293d;
        line-height: 25px;
      }
    }
  }

  &.capacities-modal {
    & .content {
      & .message {
        margin-top: 0px;

        & .subtitle {
          margin-bottom: 40px;
          color: #4a4a4a;
          font-size: 16px;
        }

        & .capacities-table {
          & .capacities-table-header {
            display: flex;
            background-color: #98acc11c;
            height: 54px;
            line-height: 54px;

            & > div {
              width: 25%;
              color: #252325cc;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
            }
          }

          & .capacity-row {
            display: flex;
            border-bottom: 1px solid #0000001a;

            & > div {
              width: 25%;
              color: #252325;
              font-size: 17px;
              height: 40px;
              line-height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;

              & .tag {
                line-height: 20px;
              }

              & input {
                height: 21px;
                width: 80px;
                font-size: 16px;
                font-weight: bold;
                line-height: 25px;
                text-align: center;
                margin: 0 5 0 5;
                border: none;
                border-bottom: 2px solid #20293d;
                outline: 0;
              }
            }
          }
        }
      }
    }
  }

  &.change-user-type-modal {

    & .content {
      width: 600px;
      margin: 0px;
      transform: translateX(-50%);
      top: 100px;

      & .content-select {
        margin-top: 30px;
        padding-left: 80px;
        padding-right: 80px;

        & .error {
          color: red;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 14px;
          margin-top: 15px;
          margin-left: 5px;
        }

      }

      & .content-buttons {
        display: flex;
        justify-content: space-between;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 26px;
        margin-top: 40px;
      }

    }

  }

  &.availabilitty-report-map-modal {

    & .subTitle {
      height: 25px;
      font-size: 14px;
      font-weight: normal;
      margin-top: -10px;
      margin-left: 50px;
      line-height: 31px;
      color: #20293d;
    }

    & .content {
      width: 840px;
      margin: 0px;
      transform: translateX(-50%);
      top: 50px;

      & .row {
        margin-left: 50px;
        margin-right: 50px;

        & .space-header {
          flex: 1;
          color: #20293D;
          font-size: 12px;
          font-weight: normal;
        }

        & .space {
          flex: 1;
          color: #32415E, 100%;
          font-size: 12px;
          font-weight: normal;
          display: flex;
          align-items: center;
        }

      }

      & .map {
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 15px;
        height: 280px;
        background-color: gray;
        border-radius: 12px;
        margin-bottom: 20px;
        position: relative;

        & .map-view {
          border-radius: 12px;
          width: auto;
          height: 280px;
        }
      }

    }

  }

  &.availability-columns-settings-modal {

    & .subTitle {
      height: 25px;
      font-size: 14px;
      font-weight: normal;
      margin-top: -10px;
      margin-left: 50px;
      line-height: 31px;
      color: #20293d;
    }

    & .content {
      width: 600px;
      margin: 0px;
      transform: translateX(-50%);
      top: 100px;

      & .row {
        display: flex;
        flex-direction: row;
        margin-left: 50px;
        margin-right: 60px;
        font-size: 14px;
        color: #20293D;
        font-weight: bold;
      }

      & .rows-content {
        max-height: 300px;
        overflow: scroll;
        margin-right: 80px;
        margin-bottom: 20px;

        & .row {
          padding-top: 5px;
          padding-bottom: 5px;
          display: flex;
          flex-direction: row;
          margin-left: 50px;
          font-size: 14px;
          color: #32415E;
          font-weight: bold;

        }

      }

    }

  }

  &.trip-control-columns-settings-modal {

    & .subTitle {
      height: 25px;
      font-size: 14px;
      font-weight: normal;
      margin-top: -10px;
      margin-left: 50px;
      line-height: 31px;
      color: #20293d;
    }

    & .content {
      width: 600px;
      margin: 0px;
      transform: translateX(-50%);
      top: 100px;

      & .row {
        display: flex;
        flex-direction: row;
        margin-left: 50px;
        margin-right: 60px;
        font-size: 14px;
        color: #20293D;
        font-weight: bold;
      }

      & .rows-content {
        max-height: 300px;
        overflow: scroll;
        margin-right: 80px;
        margin-bottom: 20px;

        & .row {
          padding-top: 5px;
          padding-bottom: 5px;
          display: flex;
          flex-direction: row;
          margin-left: 50px;
          font-size: 14px;
          color: #32415E;
          font-weight: bold;

        }

      }

    }

  }

  &.change-date-modal {

    & .subTitle {
      height: 25px;
      font-size: 14px;
      font-weight: normal;
      margin-left: 50px;
      line-height: 31px;
      color: #20293d;
    }

    & .content {
      width: 990px;
      margin: 0px;
      transform: translateX(-50%);
      top: 50px;

      & .title {
        margin-top: 30px;
        height: 40px;
      }

      & .dates_content {
        padding-left: 50px;
        padding-right: 50px;
      }

      & .tag {
        border-radius: 17px;
        background-color: red;
        color: white;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 14px;
        padding-right: 6px;
        padding-left: 6px;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-left: 15px;
      }

      & .avatar {
        display: flex;
        align-items: center;
        margin-top: 11px;

        & .logo {
          width: 30px;
          height: 30px;
          border-radius: 17px;
          margin: 5px;
        }

        & .trip {
          color: #20293d;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 14px;
        }

        & .manuever {
          color: #20293d;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 14px;
        }

      }

      & .calendar-content { 
        display: flex;
        margin-top: 11px;
        justify-content: center;
        height: 280px;

        & .warning {
          color: red;
          font-size: 13px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 14px;
          margin-left: 20px
        }

        & .react-datepicker {
          border-width: 0px;

          & .react-datepicker__header {
            background-color: white;
            border-bottom: 1px solid rgba(164, 180, 206, 1);

            & .react-datepicker__current-month {
              color: #20293d;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 14px;
            }

          }

          & .react-datepicker__month {
            border-bottom: 1px solid rgba(164, 180, 206, 1);
          }

        }

        & .title-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          & .windows-title {
            margin-top: 20px;
            margin-left: 20px;
            color: #20293d;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 14px;
          }

          & .status-description {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            margin-right: 11px;

            & .text {
              margin-top: 5px;
              margin-right: 12px;
              height: 22px;
              font-size: 12px;
              color: rgba(61, 119, 247, 1);
            }

            & .space-available {
              color: rgba(61, 119, 247, 1);
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 22px;
              background-color: white;
              border: 1px solid rgba(61, 119, 247, 1);
              border-radius: 4px;
              padding-left: 4px;
              padding-right: 4px;
              height: 20;
              margin-right: 13px;
            }

            & .space-not-allowed {
              color: rgba(61, 119, 247, 1);
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 22px;
              background-color: white;
              border: 1px dotted rgba(61, 119, 247, 1);
              border-radius: 4px;
              padding-left: 4px;
              padding-right: 4px;
              height: 20;
              background-image: url(/images/disable_background.png);
              background-repeat: no-repeat;
              background-position: left;
              background-size: cover;
              margin-right: 13px;
            }

          }

        }

        & .indications {
          color: #20293d;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 14px;
        }

        & .table-row {
          height: 40px; 
          min-height: 40px; 
          display: flex; 
          overflow-x: hidden; 
          width: 540px;

          & .no-windows-content {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454e76;
            font-size: 12px;
            font-weight: bold;
            width: 100%;
          }

          & .name-content {
            display: flex;
            width: 30%; 
            min-width: 30%;
            align-items: center;
            color: rgba(45, 54, 76, 1);
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 14px;
            justify-content: space-between;
          }

          & .space-not-allowed {
            color: rgba(61, 119, 247, 1);
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            background-color: white;
            border: 1px dotted rgba(61, 119, 247, 1);
            border-radius: 4px;
            padding-left: 4px;
            padding-right: 4px;
            cursor: not-allowed;
          }

          & .space-available {
            color: rgba(61, 119, 247, 1);
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            background-color: white;
            border: 1px solid rgba(61, 119, 247, 1);
            border-radius: 4px;
            padding-left: 4px;
            padding-right: 4px;
            cursor: pointer;
          }

          & .space-available-selected {
            color: white;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            background-color: rgba(61, 119, 247, 1);
            border-radius: 4px;
            padding-left: 4px;
            padding-right: 4px;
            cursor: pointer;
          }

        }

        & .table-content {
          max-height: 140px;
          overflow-y: scroll;
          overflow-x: hidden;
        }

      }

      & .dates {
        display: flex;
        white-space: nowrap;
        margin-bottom: 20px;

        & input {
          height: 21px;
          width: 36px;
          font-size: 16px;
          font-weight: bold;
          line-height: 25px;
          text-align: center;
          margin: 0 5 0 5;
          border: none;
          border-bottom: 2px solid #20293d;
          outline: 0;
        }

        & .appointment-title {
          color: #20293d;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
          display: flex;
        }

        & .appointment-date-active {
          color: rgba(61, 119, 247, 1);
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
          display: flex;
        }

        & .appointment-date {
          color: black;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 22px;
        }

        & .space {
          align-items: center;
          display: flex;

          color: #20293d;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;

        }

      }

      & .content-buttons {
        display: flex;
        justify-content: space-between;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 26px;
        margin-top: 10px;
      }

    }

  }

  &.emails-modal {
    & .content {
      width: 800px;
      margin: 0px;
      transform: translateX(-50%);

      & .message {
        margin-bottom: 5px;
      }

      & .admins {
        display: flex;
        margin: 0 40px;
        justify-content: flex-end;
      }

      & .result-container {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        // border: 1px solid #c0cbdd;
        border: 2px solid #c0cbdd;
        margin: 20px 0px;
      }

      // & input {
      //     display: inline-block;
      //     width: 100px;
      //     border: 0;
      //     padding: 0;
      //     margin: 0;
      //     padding: 0 5px;
      //     box-sizing: border-box;
      //     background-color: #fff;
      //     outline: 0;
      // }

      & .email-input {
        line-height: 30px;
        font-size: 15px;
        width: 100%;
        border: none;
        border-bottom: 2px solid #c0cbdd;

        &:focus {
          outline: none;
        }
      }

      & .actions {
        display: flex;
        justify-content: flex-end;
      }

      & .tags-container {
        display: flex;
        flex-wrap: wrap;
        padding-right: 5px;
        width: 100%;
        text-align: left;
        max-height: 200px;
        height: fit-content;
        overflow-y: auto;

        // span {
        //   position: relative;
        //   background-color: rgba(235, 237, 241, 1);
        //   display: inline-block;
        //   line-height: 28px;
        //   height: 28px;
        //   white-space: nowrap;
        //   margin: 4px;
        //   // padding: 0px 26px 0px 31px;
        //   padding: 0 26px 0 30px;
        //   font-size: 14px;
        //   font-weight: normal;
        //   color: #3d5074;
        //   border-radius: 6px;

        //   & .user-avatar {
        //     top: 3px;
        //     height: 21px;
        //     position: absolute;
        //     left: 6px;
        //   }

        //   b {
        //     position: absolute;
        //     line-height: 20px;
        //     top: 5px;
        //     right: 5px;
        //     font-size: 12px;
        //     cursor: pointer;
        //     width: 9px;
        //     padding: 3px;

        //     svg {
        //       fill: #a7b6d6;
        //     }
        //   }
        // }
      }

      & .tags-container:empty {
        display: none;
      }
    }
  }

  &.capacities-modal {
    & .content {
      overflow: unset;
      & .message {
        & .day-picker-popup {
          display: flex;
          flex-direction: column;

          position: absolute;
          bottom: -30%;
          border-radius: 12px;
          background-color: #ffffff;
          box-shadow: 0 2px 20px 5px rgba(0, 0, 0, 0.24);

          padding: 0 15px;
          z-index: 10;

          &.hidden {
            display: none;
          }

          & .title {
            font-size: 22px;
            font-weight: bold;
            margin-top: 17px;
            margin-left: 25px;
            line-height: 31px;
          }

          & .DayPicker {
            margin: auto;

            & .DayPicker-Body {
              border: 1px solid #a4b4ce;
              border-left: 0;
              border-right: 0;

              & .DayPicker-Day {
                &:focus {
                  outline: none;
                }
              }
            }
          }

          & .actions {
            margin: 10px 5px 20px;
            justify-content: space-around;

            & .btn {
              line-height: 30px;
              height: 30px;
              width: 100px;
            }
          }
        }
      }

      & .actions {
        justify-content: space-around;

        & .primary.outline {
          color: #4183f9;
        }
      }
    }
  }

  &.create-exemption-modal {
    & .content {
      overflow: visible;

      & .title {
        min-height: 55px;
      }

      & .message {
        position: relative;
        margin-top: 15px;
        & .id-area {
          display: flex;

          & .avatar {
            width: 60%;
            display: flex;
            align-items: center;
          }

          & .motive {
            width: 35%;

            & .select-input {
              padding-top: 0px;
            }

            & .label {
              top: 27px;
            }

            // & .label.focused {
            //   transform: translateY(-35px) scale(0.8);
            // }
          }
        }

        & .date {
          width: 50%;

          & .react-datepicker__header button {
            width: 25px;
          }
        }
      }

      & .actions {
        justify-content: space-evenly;

        & .btn.secondary {
          width: 240px;
        }
      }
    }
  }

  &.base-contract-modal {
    & .content {
      & .message {
        line-height: 20px;

        & .tags-options-container {
          margin-top: 30px;
          & .tag {
            cursor: pointer;
          }
        }
      }
    }
  }

  &.delete-contract-modal {
    & .content {
      & .message {
        & .subtitle {
          color: #20293d;
          font-size: 15px;
          margin: 10px 0 5px 0;
        }

        & .warning {
          color: #e30d0d;
          font-weight: bold;
          position: relative;
          top: 10px;
          text-align: right;
        }

        & .detail-row {
          display: flex;

          & .detail-title {
            width: 50%;
            text-align: right;
            padding-right: 13px;
            box-sizing: border-box;
            color: #20293d;
            font-size: 13px;
          }

          & .detail-content {
            color: #20293d;
            font-size: 13px;
          }
        }
      }
    }
  }

  &.confirm-limits-modal {
    & .content {
      // width: 600px;

      & .actions {
        justify-content: space-evenly;

        & .primary.outline {
          color: #4183f9;
        }
      }
    }
  }

  &.contract-modal {
    & .content {
      top: 2%;
      width: 600px;
      transform: translateX(-50%);
      overflow: visible;

      & .message {
        margin-top: 0px;
        margin-bottom: 0px;

        & .select-input,
        & .select-multiple-input {
          width: 95%;

          & optgroup {
            color: #20293d;
          }

          & .label {
            color: #20293d;

            &.focused {
              color: #9ba1ac;
            }
          }
        }

        & .contractQuantity {
          & .text-input {
            display: inline-block;
            width: 15%;
            padding-top: 0px;
            text-align: center;
            margin-bottom: 0px;

            & input {
              text-align: center;
              width: 100%;
            }
          }
        }

        & .date {
          display: flex;
          align-items: baseline;

          & .text-input {
            width: 60%;
            margin-bottom: 0px;

            & .label {
              color: #20293d;
            }

            &.has-input,
            &:focus-within {
              & > label {
                color: #9ba1ac;
              }
            }
          }

          & .checkbox-container {
            margin-left: 20px;
          }
        }

        & .add-class {
          margin-top: 40px;
        }
      }

      & .actions {
        display: flex;
        justify-content: flex-end;

        & .outline {
          color: #4183f9;
        }

        & .btn + .btn {
          margin-left: 40px;
        }
      }
    }
  }

  &.expedition-class-modal {
    & .content {
      top: 2%;
      max-height: 95%;

      & .message {
        margin-top: 0px;

        & .section-title {
          color: #20293d;
          font-family: Overpass;
          font-size: 16px;
          font-weight: bold;
        }

        & .tags {
          line-height: 19px;
        }

        & .text-input {
          width: 40%;
        }

        & .top-space {
          margin-top: 21px;
        }

        & .radio-options-container .radio-container {
          margin-bottom: -5px;
          line-height: 30px;
        }
      }

      & .actions {
        & button {
          &.primary.outline {
            color: #4183f9;
          }

          & + button {
            margin-left: 30px;
          }
        }
      }
    }
  }

  &.sortable-table-modal {
    & .content {
      top: 25px;
      transform: translateX(-50%);
      max-width: 90%;
      max-height: 93%;

      & .message {
        margin-bottom: 60px;

        & .table-sortable {
          background-color: #fff;
          & .header {
            color: #000;
            background-color: #fff;
            border-bottom: 2px solid #0000002e;
            font-size: 15px;
            font-weight: bold;
          }

          & .rows-container {
            & .row {
              & .row-content {
                color: #000
              }
            }
          }
        }
      }
    }
  }

  &.invite-users-modal {
    & .content {
      top: 25px;

      & .message {
        & .user-type-options-container {
          display: flex;
        }

        & .radio-container {
          font-size: 16px;
          letter-spacing: 0.33px;
          line-height: 25px;
          display: block;
          position: relative;
          margin: 0 12px 12px 12px;
          cursor: pointer;
          padding: 0px 35px;

          & input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
              & ~ .checkmark {
                background-color: #2196f3;

                &:after {
                  display: block;
                }
              }
            }
          }

          & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #ccc;
            border-radius: 50%;
            transition: 0.3s;

            &:after {
              content: " ";
              position: absolute;
              top: 9px;
              left: 9px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: white;
              display: none;
            }
          }
        }

        & .user-tags-options-container {
          line-height: 20px;
          max-height: 150px;
          overflow: auto;

          & .tag-body {
            cursor: pointer;
          }
        }

        & .facilities-options-container {
          height: 276px;
          margin-bottom: 30px;
          border: 1px solid #a4b4ce;
          padding: 0px 18px 0px 18px;
          box-sizing: border-box;

          & .search-container {
            position: relative;
            width: 50%;
            left: 25%;

            & .text-input-search {
              padding-top: 0px;

              & label {
                top: 25px;
              }

              & + .icono {
                position: absolute;
                right: 0px;
                bottom: 7px;
              }
            }
          }

          & .facilities-display-by {
            display: flex;

            & .facilities-radio-container {
              display: flex;
            }
          }

          & .facilities-list-container {
            overflow-y: auto;
            // max-height: 180px;
            max-height: 215px;
            overflow-x: hidden;

            & .separator {
              width: 100%;
              height: 0px;
              border: solid 0.5px #a4b4ce;
              margin-bottom: 5px;
            }

            & .sub-item {
              margin-left: 20px;
            }
          }
        }

        & .collapse-button {
          position: absolute;
          right: 0px;
          top: -40px;
        }

        & .collapse-content {
          max-height: 100px;

          & p {
            margin: 0px 10px;
          }
        }

        & .review-emails {
          border: 1px solid #a4b4ce;
          height: 100px;
          padding: 5px 10px;
          box-sizing: border-box;
          overflow-y: auto;
        }

        & .review-counters {
          display: flex;

          & > div + div {
            margin-left: 50px;
          }

          & .image.default {
            height: 25px;
            width: 25px;
            border-radius: 28px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #79a3ff;
            background-size: 15px 15px;
            margin-right: 6px;
          }

          & .review-users,
          & .review-facilities {
            display: flex;
            line-height: 28px;
          }

          & .review-roles span {
            background-color: #6988cd;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            padding: 4px 6px;
            border-radius: 4px;
          }
        }
      }

      & .actions {
        & button {
          &.primary.outline {
            color: #4183f9;
          }

          & + button {
            margin-left: 30px;
          }
        }
      }
    }
  }

  &.invite-network {
    & .content {
      // transform: translate(-50%, -50%);
      // top: 50%;
      top: 5%;

      & .message {
        & .search {
          width: 60%;
          position: relative;
          left: 20%;

          & .icono {
            position: absolute;
            right: 0;
            bottom: 7px;
          }
        }

        & .company-results {
          max-height: 200px;
          // border: 1px solid #6E7689;
          overflow-y: auto;
          padding: 0 10px;

          & .company-row {
            display: flex;
            height: 60px;

            & .logo {
              width: 10%;
              display: flex;
              justify-content: center;
              align-items: center;

              & .avatar {
                height: 48px;
                width: 48px;
                border-radius: 28px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: white;

                &.default {
                  background-size: auto;
                  border: 1px solid #a1a6a9;
                }
              }
            }

            & .credentials {
              width: 60%;
              margin: auto;
              padding-left: 10px;
              box-sizing: border-box;

              & .name {
                color: #3d5074;
                font-size: 14px;
                font-weight: bold;
              }

              & .type {
                color: #20293d;
                font-size: 12px;
                font-weight: bold;
              }
            }

            & .action {
              width: 30%;
              display: flex;
              justify-content: center;
              align-items: center;

              & .network_status {
                color: #3d5074;
                font-size: 14px;
                font-weight: bold;
                color: #3d77f7;
              }

              & button {
                height: 35px;
                line-height: 35px;
                width: fit-content;
                min-width: 135px;
                font-size: 14px;
              }
            }

            & + .company-row {
              border-top: 1px solid #d5deec;
            }
          }
        }

        & .indicator-text {
          color: #6e7689;
          font-size: 16px;
          font-weight: bold;
        }

        & .center {
          text-align: center;
        }
      }

      & .actions {
        justify-content: space-evenly;

        & .primary.outline {
          color: #4183f9;
        }
      }
    }
  }

  &.consolidate-order-modal {
    & .content {
      overflow: visible;

      & .message {
        margin-top: 0;
      }

      & .orders-container {
        margin-top: 10px;

        & .resume-header {
          color: #9ba1ac;
          font-size: 15px;
        }

        & .row-header {
          display: flex;
          font-weight: bold;
          border-bottom: solid 2px #9ba1ac;
          & > div {
            // line-height: 35px;
            padding-left: 5px;
          }
        }

        & .row-container {
          max-height: 105px;
          overflow: auto;
          border-bottom: solid 2px #9ba1ac;
          & .order-row {
            display: flex;
            & + .order-row {
              border-top: 1px solid #9ba1ac80;
            }
            & > div {
              line-height: 35px;
              padding-left: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  &.create-orders-modal {
    & button {
      width: fit-content;
      min-width: 150px;
    }

    & .text-input {
      & button {
        min-width: 0px;
      }
    }

    & .overlay {
      & .content {
        width: 1200px;

        & .message {
          margin: 0 40px 5px;
          & .first-step-options {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 115px;
    
            & > div {
              width: 30%;
            }
          }

          & .cards-container {
            max-height: 400px;
            overflow-y: auto;
            & > div {
              display: inline-block;
            }
          }

          & .second-step {
            & .headers {
              display: flex;
              align-items: baseline;

              & .counters {
                white-space: nowrap;
                font-weight: bold;
                font-size: 14px;
                // line-height: 21px;

                color: #191919;
                margin-left: 10px;
              }
            }
          }

          & .third-step {
            & .headers {
              display: flex;
              & .avatar {
                display: flex;
                margin-right: 20px;
                & .picture {

                }
                & .data {

                }
              }
            }

            & .table-title {
              font-weight: bold;
              font-size: 20px;
              line-height: 31px;
              margin: 21px 0 15px;
            }

            & .table-header {
              display: flex;
              border-bottom: 1px solid #979797;
              & div {
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #20293dcc;
                text-align: center;
              }
            }

            & .table-body {
              max-height: 200px;
              overflow-y: auto;
              border-bottom: 1px solid #979797;
            }

            & .resume {
              display: flex;
              & div {
                font-weight: bold;
                font-size: 18px;
                line-height: 31px;
                color: #20293d;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.share-fleets-modal {
    & .overlay {
      & .content {
        width: 800px;
        margin: 0px;
        transform: translateX(-50%);
        top: 20px;
        overflow: visible;

        & .actions {
          display: flex;
          justify-content: flex-end;
        }

        & .message {
          margin-bottom: 0px;
          padding-right: 15px;

          & .search-icon {
            position: absolute;
            top: 20px;
            right: 5px;
            width: 20px;
            height: 20px;
          }

          & .text-input {
            margin-left: 0px;
          }

          .input-company {
            position: relative;
            width: 100%;
          }

          .inputText {
            color: #9ba1ac;
          }

          .input-company .inputText:focus {
            border-color: blue;
            border-width: medium medium 2px;
          }

          .input-company .search-floating-label {
            position: absolute;
            pointer-events: none;
            top: 15px;
            left: 0px;
            font-size: 15px;
            transition: 0.2s ease all;
          }

          .input-company .inputText:focus ~ .search-floating-label,
          .input-company .inputText.has-input ~ .search-floating-label {
            top: -5px;
            left: 0px;
            font-size: 13px;
            opacity: 1;
          }

          & .companies-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            text-align: left;
            height: fit-content;
            overflow-y: auto;
          }

          & .companies-container:empty {
            display: none;
          }
        }
      }
    }
  }

  &.suggestions-modal {
    & .overlay {
      & .content {
        width: 800px;
        margin: 0px;
        transform: translateX(-50%);
        top: 20px;
        overflow: visible;

        & .message {
          margin-bottom: 0px;

          & .shared-tags {
            max-height: 70px;
            overflow-y: auto;
          }

          & .chip {
            width: fit-content;
            display: inline-block;
            white-space: nowrap;
            font-size: 13px;
            font-weight: bold;
            line-height: 12px;
            max-height: 26px;
            box-sizing: border-box;
            border: 1px solid;
            border-radius: 6px;
            padding: 6px 9px;
            margin: 6px 4px;
            cursor: pointer;

            &.clear {
              color: #a4b4ce;
              border-color: #a4b4ce;
              transition: 0.4s ease-in-out;
            }

            &.full {
              color: #ffffff;
              background-color: #6988cd;
              border-color: #6988cd;
              transition: 0.4s ease-in-out;
            }

            &.mid {
              background: repeating-linear-gradient(
                135deg,
                rgba(61, 119, 247, 0.3) 5px,
                rgba(61, 119, 247, 0.3) 8px,
                #3d77f7,
                #3d77f7 9px
              );
              color: #000;
              border: 1px solid #3d77f7;
            }
          }

          & .item-rows {
            border: 1px solid #a4b4ce;
            max-height: 210px;
            overflow-y: auto;

            & .suggestion-item-row {
              border-bottom: 1px solid #d5deec;
              padding: 3px;

              & .credentials {
                color: #3d5074;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 2px;

                & img {
                  height: 30px;
                  margin: 0 10px;
                  transform: translateY(6px);
                }
              }

              & .tags {
                display: flex;
                min-height: 38px;

                & > div {
                  overflow: auto;
                  display: flex;
                }

                & .active-tags {
                  width: 65%;
                }

                & .suggestions {
                  width: 35%;
                }
              }
            }
          }

          & .add-tags {
            margin: 10px 0px;

            & .add-tags-container {
              display: inline-flex;
              position: relative;
              left: 15px;
            }

            & .add-icon {
              display: inline-block;
              height: 30px;
              width: 30px;
              border-radius: 16px;
              text-align: center;
              line-height: 35px;
              color: #fff;
              font-weight: bold;
              font-size: 24px;
              cursor: pointer;
              background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
            }

            & .tags-tooltip {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateX(103%);
              width: 300px;
              max-height: 300px;
              overflow-y: auto;
              border-radius: 6px;
              color: #000;
              font-size: 14px;
              text-align: left;
              background-color: #ffffff;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
              z-index: 2;
              padding: 10px 20px;
              // padding-top: 0px;
              box-sizing: border-box;

              &.hidden {
                display: none;
              }

              & .text-input-search {
                padding-top: 0px;
                margin: 5px 0;

                & label {
                  top: 25px;
                }

                &:after {
                  bottom: 20px;
                }
              }

              & .tags-list {
                max-height: 135px;
                overflow-y: auto;
              }
            }
          }
        }
      }
    }
  }

  &.tags-modal {
    & .content {
      width: 750px;
      transform: translateX(-50%);
      top: 30px;

      & .actions {
        display: flex;
        justify-content: center;

        & button {
          margin: 0 60px;

          &.outline {
            color: #4183f9;
          }
        }
      }

      & .message {
        margin-bottom: 20px;
        margin-top: 0px;

        & .color-bubble {
          height: 32px;
          width: 32px;
          border-radius: 32px;
          display: inline-block;
          margin: 0 7px;
          cursor: pointer;
          padding: 4px;
          background-clip: content-box;
          border: 2px solid #fff;

          &.selected {
            border-color: #3d77f7;
          }
        }

        & .text-input {
          padding-top: 0px;

          & .field {
            padding-top: 0px;
          }

          & input {
            color: #000;
            font-weight: bold;
          }
        }

        & .modal-title {
          margin-bottom: 0px;
        }

        & .tag-body {
          line-height: 18px;
        }

        & .roles-title {
          color: #20293d;
          font-size: 14px;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        & .roles-list {
          // max-height: 160px;
          max-height: 220px;
          overflow-y: hidden;
          // margin-top: 20px;
          border: 1px solid;
          // padding: 0 15px;
          // padding-left: 15px;
          // box-sizing: border-box;

          & .roles-list-header {
            background-color: #a4b4ce33;
            display: flex;
            margin-left: -15px;
            padding-left: 15px;
            height: 54px;

            & .permission-header {
              width: 50%;
            }

            & .switch-header {
              width: 12.5%;
              text-align: center;
              color: #20293d;
              font-size: 14px;
              font-weight: bold;
              line-height: 54px;
            }
          }

          & .roles-list-rows {
            // max-height: 110px;
            max-height: 165px;
            overflow-y: auto;
            padding-left: 15px;
            box-sizing: border-box;
          }

          & .permission-row {
            display: flex;
            margin: 15px 0;

            & .permission-title {
              width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #20293d;
              font-size: 16px;
            }

            & .switch-row-container {
              width: 12.5%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &.ramps-modal {
    & .content {
      width: 700px;
      min-height: 400px;
      transform: translateX(-50%);
      background-image: none;
      padding: 25px;
      color: #20293d;

      & .header {
        display: flex;
        height: 45px;
        line-height: 60px;

        & .title-input {
          width: 60%;
          font-size: 24px;
          font-weight: bold;
          margin-right: 50px;
          box-sizing: content-box;
        }

        & .ramp-type {
          width: 40%;
          font-weight: bold;
          border-bottom: 1px solid;
        }
      }

      & .intro {
        margin-top: 25px;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
      }

      & .name-bar {
        padding: 0;
        margin: 0;

        & label {
          top: 25px;
        }
      }

      & .type-column {
        width: 33%;
        padding: 10px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
      }

      & .icon-column {
        width: 7%;
      }

      & .times-column {
        width: 45%;
        display: flex;

        & > div {
          width: 100%;
          padding-right: 20px;
          box-sizing: border-box;
          text-align: center;
        }

        & input {
          width: 35px;
          text-align: center;
          border: 0;
          border-bottom: 1px solid #000;
          font-size: 16px;
        }
      }

      & .double-column {
        position: relative;
        width: 15%;
      }

      & .equips-table {
        height: 325px;
        margin-bottom: 25px;
      }

      & .table-header {
        display: flex;
        padding-bottom: 40px;
        color: #454e76;
        font-size: 14px;
        font-weight: bold;
        min-height: 35px;

        & .double-column .switch-container {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 60%);
        }
      }

      & .table-rows-container {
        height: 265px;
        overflow-y: auto;
      }

      & .table-row {
        display: flex;
        height: 60px;
        border-bottom: 1px solid #979797;
      }

      & .single-and-double-column {
        width: 67%;

        & > div {
          height: 50%;
          line-height: 30px;
          display: flex;
        }

        & > div.unique {
          height: 100%;
          line-height: 60px;
        }

        & > div.unique .times-column input,
        & > div.unique .switch-container {
          position: relative;
          top: 17px;
        }

        & > div > div {
          width: 22.38%;
          text-align: center;
          text-decoration: underline;
        }

        & .icon-column {
          width: 10.44%;
          position: relative;
        }

        & .icon-column img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        & .times-column {
          width: 67.14%;
          display: flex;
        }

        & .times-column > div {
          width: 100%;
        }
      }

      & .buttons {
        position: relative;
        margin-right: 60px;
        text-align: right;

        & > button {
          margin: 0 10px;
        }

        & > button:first-child {
          position: absolute;
          left: 0px;
        }
      }

      & button.primary.outline {
        color: #3d77f7;
        box-shadow: none;
      }
    }

    & .ramp-type-tootltip {
      opacity: 1;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);
      margin-top: 1px;
      border-radius: 12px;

      & > div {
        text-align: center;
      }

      &:before,
      &:after {
        content: none;
      }

      & .check-container {
        margin: 0 0 12px 0;
      }
    }

    & .read-only {
      pointer-events: none;
    }
  }

  & .delete-user {
    width: 650px !important;
  }

  &.switch-modal {
    & .overlay .content {
      & .message {
        margin-top: 0px;

        & > p {
          font-size: 14px;
          margin-bottom: 0px;
        }

        & .text-input-search {
          padding-top: 0px;
          width: 50%;
          position: relative;
          left: 25%;
        }

        & .label {
          top: 25px;
        }

        & .switch-rows-container {
          border: 1px solid #a4b4ce;
          border-radius: 6px;
          max-height: 275px;
          overflow-y: auto;
          padding: 0 10px;

          & .elem-switch-row {
            padding: 10px;

            & > div {
              &:first-child {
                position: relative;
                height: 45px;
                color: #3d5074;
                font-size: 16px;
                font-weight: bold;
                display: flex;
                align-items: center;

                & .right-align {
                  position: absolute;
                  top: 0;
                  right: 0px;
                  display: flex;
                  align-items: center;
                  height: 45px;
                }

                & .switch-container {
                  margin-left: 15px;
                }
              }

              &:last-child {
                padding-left: 42px;
              }
            }

            & img {
              height: 32px;
              margin-right: 10px;
            }

            &:hover {
              background-color: rgba(164, 180, 206, 0.2);
            }

            & + .elem-switch-row {
              border-top: 1px solid rgba(61, 80, 116, 0.1);
            }
          }
        }
      }

      & .actions {
        text-align: center;
        display: flex;

        & button {
          margin: 0 80px;
        }

        & .primary.outline {
          color: #4081f9;
        }
      }
    }
  }

  & .delete-facility {
    width: 650px !important;

    & button {
      width: 155px;

      // &:last-child {
      //   position: absolute;
      //   right: 170px;
      //   bottom: 0px;
      // }
    }

    & .message {
      margin-bottom: 20px !important;
    }
  }

  & .unshare_operator {
    width: 650px !important;

    & .message {
      margin-bottom: 50px !important;
    }
  }

  &.edit-network-contact {
    & .content {
      width: 600px;

      & .credentials {
        display: flex;

        & .logo {
          width: 13%;

          & .avatar {
            height: 48px;
            width: 48px;
            border-radius: 28px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: white;

            &.default {
              background-size: auto;
              border: 1px solid #969ca1;
            }
          }
        }

        & .name {
          font-weight: bold;
        }
      }

      & .alias {
        width: 50%;
        margin-left: 12%;
      }

      & .code {
        width: 50%;
        margin-left: 12%;
      }

      & .actions button + button {
        margin-left: 30px;
      }
    }
  }

  & .result-message {
    width: 600px !important;

    & .message {
      margin-bottom: 65px !important;
    }
  }

  & .end-relationship-message {
    width: 600px !important;

    & .message {
      margin-bottom: 25px !important;
      color: #7b8293 !important;
      font-size: 14px;
    }

    & .credentials {
      display: flex;
      margin-top: 20px;
      margin-left: 15px;

      & .logo {
        width: 13%;

        & .avatar {
          height: 48px;
          width: 48px;
          border-radius: 28px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: white;

          &.default {
            background-size: auto;
            border: 1px solid #969ca1;
          }
        }
      }

      & .name {
        color: #3d5074;
        font-size: 14px;
        font-weight: bold;
      }

      & .orbi-id {
        color: #3d5074;
        font-size: 12px;
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }

  & .invite-message {
    width: 840px !important;
    margin-left: -420px !important;

    & .message {
      margin-bottom: 65px !important;
    }

    & .input-tagger-container {
      margin-top: 25px;
    }

    & .email-input {
      line-height: 30px;
      font-size: 15px;
      width: 100%;
      border: none;
      border-bottom: 2px solid #c0cbdd;

      &:focus {
        outline: none;
      }
    }

    & .dialog.emails-modal .content .tags-container {
      display: flex;
      flex-wrap: wrap;
      padding-right: 5px;
      width: 100%;
      text-align: left;
      max-height: 200px;
      height: -moz-fit-content;
      height: fit-content;
      overflow-y: auto;
    }
  }

  & .bi-business-intelligence-modal-name {
    width: 840px !important;
    margin-left: -420px !important;

    & .message {
      margin-bottom: 65px !important;
      font-size: 30px;
      font-weight: bold;
    }

    & .input-tagger-container {
      margin-top: 45px;
    }
  }
}

/* Controls */

.catalog-card {
  min-height: 160px;
  width: 288px;
  border-radius: 12px;
  background-color: #2f3b52;
  display: inline-block;
  /* border-top: #2F3B52 4px solid; */
  // border-top: transparent 4px solid;
  transition: 0.5s;
  margin: 5px;
  box-sizing: border-box;
  position: relative;
  width: 24%;
  min-width: 275px;

  &:hover {
    /* border-top: #526A99 4px solid; */
    position: relative;
    background-color: rgba(82, 106, 153, 0.4);
    box-shadow: 10px 20px 40px 5px rgba(0, 0, 0, 0.6);
  }

  & .overlay {
    display: none;

    & .checkbox {
      position: absolute;
      top: 14px;
      left: 18px;
      height: 33px;
      width: 33px;
      border-radius: 4px;
      background-color: #a7b6d6;
      transition: 0.3s ease;
    }
  }

  &.selected {
    & .checkbox {
      background-color: #3d77f7;

      &:after {
        content: "";
        height: 7px;
        width: 15px;
        border-bottom: 4px solid #fff;
        border-left: 4px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -69%) rotate(-45deg);
      }
    }
  }

  &.selection .overlay {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    z-index: 2;
  }

  & .tag .tag-body {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .__react_component_tooltip {
    & .tag .tag-body {
      max-width: fit-content;
    }
  }

  &.user-card {
    border-top: transparent 4px solid;
    transition: 0.5s;
    box-sizing: border-box;

    &.user-status-5,
    &.user-status-3,
    &.user-status-1 {
      & .overlay {
        display: none;
      }
    }

    &.user-status-1:hover,
    &.user-status-3:hover,
    &.user-status-5:hover {
      & .content,
      & .footer {
        filter: blur(2px);
        -webkit-filter: blur(2px);
      }

      & .actions {
        background-color: #1a202e;
        display: grid;
        position: absolute;
        opacity: 0.9;
        height: 103%;
        width: 100%;
        top: -4;
        border-radius: 12px;
        z-index: 1;
      }
    }

    &.user-status-1:hover,
    &.user-status-3:hover {
      &.selection {
        & .content,
        & .footer {
          filter: none;
          -webkit-filter: none;
        }
      }
    }

    &.user-type-2,
    &.user-type-4 {
      border-top: #ffb643 4px solid;
    }

    &.user-type-1,
    &.user-type-3 {
      border-top: #79a3ff 4px solid;
    }

    &.user-type-5,
    &.user-type-6 {
      border-top: #79a3ff 4px solid;
    }

    & .actions {
      display: none;

      & .status {
        // margin: 40px auto 0 auto;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
      }

      & .buttons {
        margin: 0 auto 0 auto;
        display: flex;

        & button {
          margin-top: auto;
          margin-bottom: auto;
          height: 30px;
          border-radius: 6px;
          width: 110px;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          padding: 0;
          margin-left: 4px;
          margin-right: 4px;
          box-sizing: border-box;

          &:hover {
            color: rgba(255, 255, 255, 1) !important;
            background-image: linear-gradient(
              106deg,
              #2f59f1,
              #419ef9
            ) !important;
          }
        }
      }
    }

    & .content {
      padding: 12px;
      height: 111px;
      box-sizing: border-box;
      position: relative;

      & .contact {
        padding-bottom: 4px;
        display: flex;

        & .image {
          height: 56px;
          width: 56px;
          min-width: 56px;
          border-radius: 28px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: white;

          &.default {
            background-size: auto;
          }

          & .connection-state {
            box-sizing: border-box;
            height: 11px;
            width: 11px;
            border: 2px solid #2f3b52;
            margin-right: 6px;
            position: relative;
            margin-left: auto;
            border-radius: 5px;
            margin-top: 48px;
            position: inherit;

            &.online {
              background-color: #6de155;
            }

            &.off-line {
              background-color: #a4b4ce;
            }

            &.away {
              background-color: #ffb643;
            }
          }
        }

        & .contact-data {
          margin: auto 10px auto 10px;
          display: grid;
          overflow: hidden;

          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          & .name {
            color: #ffffff;
            font-size: 14px;
            line-height: 22px;
          }

          & .email {
            opacity: 0.8;
            color: #afc8ff;
            font-size: 12px;
            line-height: 18px;
            // display: contents;
          }
        }
      }

      & .connectivity {
        display: none;

        &.user-status-4 {
          display: block;
          color: #a4b4ce;
          font-size: 12px;
          line-height: 28px;
        }
      }

      & .status {
        display: block;
        color: #ffffff;
        font-size: 12px;
        line-height: 28px;
        text-align: center;

        &.user-status-4 {
          display: none;
        }

        // &.pending,
        // &.unverified,
        // &.invited {
        //   display: block;
        //   color: #FFFFFF;
        //   font-size: 12px;
        //   line-height: 28px;
        //   text-align: center;
        // }
      }
    }

    & .footer {
      border-top: 1px #444e63 solid;
      margin: auto 6px auto 6px;
      display: flex;
      font-size: 12px;
      height: 36px;
      box-sizing: border-box;

      & .facilities {
        margin: auto 4px auto 8px;
        width: 85px;
        height: 12px;
        width: 28%;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        color: #fff;

        & > span {
          line-height: 18px;
        }
      }

      & .tags {
        margin: auto 8px auto 4px;
        display: flex;
        width: 160px;
        justify-content: flex-end;
      }
    }
  }
}

.shipment-row {
  background-color: #3d5074;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: rgba(164, 180, 206, 0.4);
  box-sizing: border-box;
  transition: 0.6s;
  position: relative;

  &:hover {
    background-color: #526a99;
    box-shadow: 0 5px 24px 10px #20293d;
    z-index: 2;
  }

  & .overlay {
    display: none;

    & .checkbox {
      position: absolute;
      top: 14px;
      left: 18px;
      height: 33px;
      width: 33px;
      border-radius: 4px;
      background-color: #a7b6d6;
      transition: 0.3s ease;
    }
  }

  & .row-content {
    display: flex;
    position: relative;
    padding: 0px;
  }

  &.selected {
    & .checkbox {
      background-color: #3d77f7;

      &:after {
        content: "";
        height: 7px;
        width: 15px;
        border-bottom: 4px solid #fff;
        border-left: 4px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -69%) rotate(-45deg);
      }
    }
  }

  &.selection {
    border: 1px solid #79a3ff;

    & .overlay {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
    }
  }
}

.catalog-row {
  height: 80px;
  width: 100%;
  background-color: #3d5074;
  padding: 11px;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: rgba(164, 180, 206, 0.4);
  box-sizing: border-box;
  transition: 0.6s;
  position: relative;

  &:hover {
    background-color: #526a99;
    box-shadow: 0 5px 24px 10px #20293d;
    z-index: 2;
  }

  & .overlay {
    display: none;

    & .checkbox {
      position: absolute;
      top: 14px;
      left: 18px;
      height: 33px;
      width: 33px;
      border-radius: 4px;
      background-color: #a7b6d6;
      transition: 0.3s ease;
    }
  }

  & .row-content {
    display: flex;
    position: relative;
    padding: 0px;
  }

  &.selected {
    & .checkbox {
      background-color: #3d77f7;

      &:after {
        content: "";
        height: 7px;
        width: 15px;
        border-bottom: 4px solid #fff;
        border-left: 4px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -69%) rotate(-45deg);
      }
    }
  }

  &.selection {
    border: 1px solid #79a3ff;

    & .overlay {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
    }
  }

  // &.contract-row {
  //   & .tag .tag-body {
  //     max-width: none;
  //   }
  // }

  & .tag .tag-body {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .__react_component_tooltip {
    & .tag .tag-body {
      max-width: fit-content;
    }
  }

  &.user-row {
    &.products-row {
      padding: 0;
    }

    & .row-content {
      display: flex;
      position: relative;
      padding: 0px;

      &.products-row {
        padding: 11px;
        // & > div {
        //   border: 1px solid;
        // }
        & .products {
          width: 9%;
        }
        & .danger {
          width: 5%;
        }
        & .tags {
          width: 11%;
        }
      }

      & .contact {
        // width: 280px;
        width: 20%;
        display: flex;

        & .image {
          height: 48px;
          width: 48px;
          margin: 5px 9px;
          border-radius: 28px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: white;

          &.default {
            background-size: auto;
          }

          & .connection-state {
            box-sizing: border-box;
            height: 11px;
            width: 11px;
            border: 2px solid #2f3b52;
            margin-right: 6px;
            position: relative;
            margin-left: auto;
            border-radius: 5px;
            margin-top: 40px;

            &.online {
              background-color: #6de155;
            }

            &.off-line {
              background-color: #a4b4ce;
            }

            &.away {
              background-color: #ffb643;
            }
          }
        }

        & .contact-data {
          display: grid;
          padding: 10px 10px 11px 15px;

          & .name {
            color: #ffffff;
            font-size: 14px;
            line-height: 18px;
          }

          & .email {
            color: #a4b4ce;
            font-size: 12px;
            line-height: 15px;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      & .role {
        // width: 90px;
        width: 10%;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        box-sizing: border-box;

        &.user-type-1,
        &.user-type-3,
        &.user-type-5,
        &.user-type-6 {
          color: #79a3ff;
        }

        &.user-type-2,
        &.user-type-4 {
          color: #ffb643;
        }
      }

      & .historical-data {
        width: 20%;
        color: rgba(164, 180, 206, 1);
        align-items: center;
        justify-content: center;
        display: flex;
      }

      & .historical-details {
        width: 40%;
        color: rgba(164, 180, 206, 1);
        align-items: center;
        // justify-content: center;
        display: flex;
      }

      & .facilities {
        margin-top: auto;
        margin-bottom: auto;
        // width: 90px;
        width: 20%;
        color: #ffffff;
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .tags {
        width: 17%;
        display: flex;
        justify-content: center;
      }

      & .products {
        width: 9%;
        color: rgba(164, 180, 206, 1);
        align-items: center;
        justify-content: center;
        display: flex;
      }

      & .status {
        width: 13%;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 12px;
        color: #ffffff;
        font-size: 12px;
        line-height: 18px;
        display: flex;

        & .status-text {
          // width: 100px;
          margin: 5px;
          line-height: 15px;
        }

        & .icon {
          height: 16px;
          width: 12px;
          color: #3d5074;
          background-color: #a7b6d6;
          border-radius: 30px;
          font-size: medium;
          font-weight: bolder;
          margin-top: auto;
          margin-bottom: auto;
          padding: 2px 2px 2px 5px;

          &.user-status-4 {
            background-color: inherit;
            color: #a7b6d6;
          }

          &.user-status-1,
          &.user-status-2,
          &.user-status-3 {
            padding: 2px 2px 0px 5px;
          }

          &.user-status-5 {
            padding: 2px 0px 0px 6px;
          }
        }
      }

      & .session {
        margin-top: auto;
        margin-bottom: auto;
        color: #a4b4ce;
        font-size: 12px;
        line-height: 18px;
        width: 120px;
        width: 18%;
      }

      & .complete-table {
        width: 40% !important;
      }

      & .products-table {
        width: 36% !important;
      }

      & .actions {
        width: 250px;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;

        & button {
          margin-top: auto;
          margin-bottom: auto;
          height: 26px;
          border-radius: 6px;
          width: 110px;
          font-size: 14px;
          line-height: 25px;
          text-align: center;
          padding: 0;
          margin-left: 4px;
          margin-right: 4px;

          &:hover {
            color: rgba(255, 255, 255, 1) !important;
            background-image: linear-gradient(
              106deg,
              #2f59f1,
              #419ef9
            ) !important;
          }
        }
      }
    }
  }

  &.company-row {
    margin: 0 2%;
    width: auto;
    padding: 11px 0;

    & .row-content > div {
      align-items: center;
      justify-content: center;
      display: flex;

      &.__react_component_tooltip {
        display: initial;

        & div + div {
          margin-top: 8px;
        }
      }
    }

    & .logo {
      width: 8%;
      justify-content: flex-end !important;

      & .avatar {
        height: 48px;
        width: 48px;
        border-radius: 28px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: white;

        &.default {
          background-size: auto;
        }
      }
    }

    & .invitations {
      width: calc(8.5%);
      min-width: calc(8.5%);
      max-width: calc(8.5%);
      font-size: 11px;
      font-weight: bold;
      color: #ffffff;
      flex-direction: column;
    }

    & .credentials {
      width: 27%;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      flex-direction: column;

      & .principal {
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & .alternative {
        display: flex;
        width: 90%;
        justify-content: space-between;
        margin-top: 4px;

        & > div {
          font-size: 15px;
          font-weight: 300;
          width: 50%;
          text-align: center;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    & .orbi-id {
      width: 15%;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }

    & .partners-since {
      width: 25%;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }

    & .company-type {
      width: 20%;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }

    & .status {
      width: 25%;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;

      & button {
        width: 100px;

        & + button {
          margin-left: 20px;
        }
      }
    }

    & .actions {
      width: 5%;

      & .ellipsis {
        padding: 15px 10px;
        cursor: pointer;

        & b {
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: #ccc;
          display: block;

          &:before {
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #ccc;
            display: block;
            content: "";
            position: absolute;
            top: 11px;
          }

          &:after {
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #ccc;
            display: block;
            content: "";
            position: absolute;
            bottom: 10px;
          }
        }
      }
    }

    & .tooltip-option {
      color: #2f4ff1;
      cursor: pointer;
    }
  }

  &.contract-row {
    margin: 0 2%;
    width: auto;
    padding: 11px 0;

    & .row-content > div {
      align-items: center;
      justify-content: center;
      display: flex;

      &.__react_component_tooltip {
        display: initial;

        &.hidden {
          display: none !important;
        }

        & .count {
          color: #3d2f2f;
          font-size: 14px;
          font-weight: bold;
        }

        & .date {
          font-size: 11px;
          margin-top: 0px;
        }

        & .expired {
          & .date {
            color: #d0021b;
          }
        }

        & .near-expiration {
          & .date {
            color: #f5a623;
          }
        }

        & div + div {
          margin-top: 8px;
        }
      }
    }

    & .facility {
      width: 25%;

      & .logo {
        width: 30%;
        align-items: center;
        display: flex;
        justify-content: flex-end;

        & .avatar {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: white;
          position: relative;

          & img {
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      & .credentials {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 70%;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        flex-direction: column;

        & .principal {
          max-width: 85%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & .alternative {
          display: flex;
          width: 90%;
          justify-content: space-between;
          margin-top: 4px;

          & > div {
            font-size: 15px;
            font-weight: 300;
            width: 50%;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }

    & .transport-line {
      width: 25%;

      & .logo {
        width: 30%;
        align-items: center;
        display: flex;
        justify-content: flex-end;

        & .avatar {
          height: 48px;
          width: 48px;
          border-radius: 28px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: white;
        }
      }

      & .credentials {
        width: 70%;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        flex-direction: column;

        & .principal {
          max-width: 85%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & .alternative {
          display: flex;
          width: 90%;
          justify-content: space-between;
          margin-top: 4px;

          & > div {
            font-size: 15px;
            font-weight: 300;
            width: 50%;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }

    & .transport-class {
      width: 25%;
      font-size: 18px;
      color: #ffffff;
      display: block !important;
      text-align: center;
    }

    & .base {
      width: 12%;
      font-size: 16px;
      color: #ffffff;

      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow-x: hidden;
      // display: block !important;
      // line-height: 48px;
      // text-align: center;
    }

    & .units {
      width: 10%;
      font-size: 16px;
      color: #ffffff;
    }

    & .actions {
      width: 3%;

      & .ellipsis {
        padding: 15px 10px;
        cursor: pointer;

        & b {
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: #ccc;
          display: block;

          &:before {
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #ccc;
            display: block;
            content: "";
            position: absolute;
            top: 11px;
          }

          &:after {
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #ccc;
            display: block;
            content: "";
            position: absolute;
            bottom: 10px;
          }
        }
      }
    }

    & .tooltip-option {
      color: #2f4ff1;
      cursor: pointer;
    }
  }
}

.product-row {
  display: flex;
  height: 50px;
  transition: .4s ease;
  width: 100%;
  &:hover {
    background-color: #e9f5ff;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 40px;
    color: #4a4a4a99;

    &.avatar {
      & img {
        height: 30px;
        border-radius: 50%;
        border: 4px solid #0077E2;
    
        &.default {
          border: none;
          height: 38px;
        }
      }

      & div {
        font-size: 16px;
        font-weight: bold;
        color: #4a4a4a;
        width: calc(100% - 38px);
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.product-card {
  width: 280px;
  background-color: #20293D;
  color: #fff;
  border-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  margin: 50px 10px 0;
  transition: .6s;
  box-shadow: 2px 2px 9px 1px #19191947;

  &:hover {
    background-color: #3D5892;
    // transition: 1s;
  }

  & input {
    border-radius: 5px;
    width: 70px;
  
    &:focus-visible {
      outline: none;
    }
  }

  & .product-image {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    & img {
      height: 100px;
      border-radius: 50%;
      border: 4px solid #0077E2;

      &.default {
        border: none;
        height: 108px;
      }
    }

    & div {
      font-weight: bold;
      font-size: 12px;

      color: #79A3FF;
      white-space: nowrap;
    }
  }

  & .top-row {
    display: flex;
    justify-content: space-between;
    min-height: 42px;

    & .added {
      font-weight: normal;
      font-size: 14px;
      line-height: 5px;
      text-align: center;

      color: #FFFFFF;
    }
  }

  & .product-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 5px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .product-weigth {
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    
    color: #BFD3FF;
  }
}

.color-selector {
  width: 100%;
  padding-top: 14px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: solid 2px #9ba1ac;
  margin: 5px;
  max-height: 45px;

  // &.clear {
  //   background: white;
  //   color: #20293d;

  //   & input {
  //     color: #20293d;
  //   }

  //   & label {
  //     color: #20293d;
  //   }
  // }

  &::after {
    display: block;
    width: 0%;
    position: absolute;
    bottom: -2;
    left: 100%;
    transform: translateX(-100%);
    right: 0;
    background-color: #9ba1ac;
    height: 3px;
    content: "";
    transition: width 0.9s ease-in-out;
  }

  &:focus-within::after {
    transform: translateX(0%);
    width: 100%;
    left: 0;
    background-color: #2f4ff1;
    transition: width 0.9s ease-in-out;
  }

  &:focus-within .window {
    display: flex;
    // margin-top: 13px;
    // width: 100%;
  }

  // &:focus-within .window .color-item {
  //   display: block;
  //   // width: 100%;
  // }

  & input {
    display: block;
    width: 90%;
    height: 50%;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 10px;
    top: 20px;
    background-color: transparent;
    border: 0;
    position: relative;
    color: #9ba1ac;
    padding-top: 15px;
    padding-bottom: 3px;
    // z-index: 2;

    &:focus {
      outline: 0;
    }
  }

  & label {
    color: #9ba1ac;
    font-size: 15px;
    position: absolute;
    top: 39px;
    left: 0;
    line-height: 14px;
    transition: all 0.2s ease-in-out;
  }

  & .icon {
    position: absolute;
    right: 0%;
    bottom: 0%;
  }

  & .window {
    top: 100%;
    position: absolute;
    border-radius: 6px;
    display: none;
    width: 100%;
    height: 100px;
    // max-height: 150px;
    background-color: white;
    flex-wrap: wrap;

    & .color-item {
      width: 40px;
      height: 40px;
      margin: 5px;
      border-radius: 6px;
      cursor: pointer;
      border: solid 1px black;
      z-index: 3;
    }
  }

  & input.has-input + label,
  & input:focus + label {
    opacity: 0.6;
    color: #9ba1ac;
    font-size: 14px;
    transform-origin: 0 0;
    transform: translateY(-24px) scale(0.8);
    line-height: 16px;
  }
}

button {
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  font-family: overpass;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(8, 236, 186, 0.28);
  height: 40px;
  border: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  // align-content: center;
  align-items: center;

  &.fit-width {
    width: fit-content;
  }

  &.fit-all-width {
    width: 100%;
  }

  &.btn {
    min-width: 150px;
  }

  &.company {
    background: rgb(67, 83, 119);
  }

  &.primary {
    background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);

    &.outline {
      color: rgb(232, 240, 255);
      border: 2px solid #4183f9;
      // padding: 0px;
      margin: 0 10px 0 10px;
      background: none;

      &.white {
        color: #ffffff;
        border: 2px solid #ffffff;
      }

      &.dark {
        color: #3d77f7;
      }
    }

    &.outline-with-padding {
      color: rgb(232, 240, 255);
      border: 2px solid #4183f9;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0 10px 0 10px;
      background: none;

      &.white {
        color: #ffffff;
        border: 2px solid #ffffff;
      }

      &.dark {
        color: #3d77f7;
      }

      &.blue {
        color: #4183f9;
      }
    }
  }

  &.secondary {
    color: #702e00;
    background: linear-gradient(50.78deg, #ff8431 0%, #ffc147 100%);

    &.white {
      color: white;
    }
  }

  &.terciary {
    color: #fff;
    background: linear-gradient(20deg, #f67554 0%, #d757d4 100%);
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.circle {
    height: 48px;
    width: 48px;
    border-radius: 23px;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    line-height: 37px;
    text-align: center;
    background-color: #2f3b52;
    box-shadow: 6px 4px 6px 0 rgba(0, 0, 0, 0.16);

    &.small {
      width: 25px;
      line-height: 1.6;
    }

    &.active {
      background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
      box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.24);

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    &.done {
      background-color: #20293d;
      box-shadow: 4px 6px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  & .icon {
    margin: 5px 10px -4px 10px;
    background-repeat: no-repeat;
    background-color: transparent;
    height: 24px;
    width: 24px;
  }

  &.small {
    height: 25px;
    width: 120px;
    line-height: 25px;
    font-weight: 100;
    font-size: 14px;
  }

  &.fit-width {
    width: fit-content;
  }
}

.select-input {
  width: 100%;
  padding-top: 14px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: solid 2px #9ba1ac;
  margin: 5px;
  max-height: 45px;

  &.invalid::after {
    transform: translateX(0%);
    width: 100%;
    left: 0;
    background-color: red;
    transition: width 0.9s ease-in-out;
  }

  &.clear {
    & select {
      &.has-input {
        color: #20293d;
      }

      & option {
        background: white;
        color: #20293d;
      }
    }

    & label {
      color: #20293d;
    }
  }

  &::after {
    display: block;
    width: 0%;
    position: absolute;
    bottom: -2;
    left: 100%;
    transform: translateX(-100%);
    right: 0;
    background-color: #9ba1ac;
    height: 3px;
    content: "";
    transition: width 0.9s ease-in-out;
  }

  &:focus-within::after {
    transform: translateX(0%);
    width: 100%;
    left: 0;
    background-color: #2f4ff1;
    transition: width 0.9s ease-in-out;
  }

  & select {
    border: 0 !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    background-color: transparent;
    position: relative;
    // color: #9BA1AC;
    color: transparent;
    padding-top: 15px;
    padding-bottom: 3px;

    &:hover ~ .caret {
      width: 24px;
      height: 16px;
      top: 65%;
    }

    &:focus {
      outline: 0;
    }

    &.has-input {
      color: #9ba1ac;
    }

    & option {
      // border:none;
      // margin: 40px;
      // background: #242e42;
      color: black;

      // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }

    & optgroup {
      // border:none;
      // margin: 40px;
      // background: #242e42;
      color: black;
      padding-left: 5px;
      // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
  }

  & > .caret {
    background: url("/images/select-arrow.svg") no-repeat;
    display: block;
    position: absolute;
    right: 0%;
    top: 70%;
    width: 20px;
    height: 12px;
    transform: rotate(360deg);
    transition: transform 0.5s ease-in-out;
    background-size: contain;
  }

  & select:focus ~ .caret {
    transform: rotate(180deg);
    transition: transform 0.5s ease-in-out;
  }

  & > label {
    color: #9ba1ac;
    font-size: 15px;
    position: absolute;
    top: 39px;
    left: 0;
    line-height: 14px;
    transition: all 0.2s ease-in-out;

    &.focused {
      opacity: 0.6;
      color: #9ba1ac;
      font-size: 14px;
      transform-origin: 0 0;
      transform: translateY(-24px) scale(0.8);
      line-height: 16px;
    }
  }

  & select.has-input ~ label,
  & select:focus ~ label & label.focused {
    opacity: 0.6;
    color: #9ba1ac;
    font-size: 14px;
    transform-origin: 0 0;
    transform: translateY(-24px) scale(0.8);
    line-height: 16px;
  }
}

.step-title {
  display: flex;
  font-weight: bold;
  font-size: 24px;
  line-height: 37px;
  color: #FFFFFF;

  & .number {
    width: 40px;
    text-align: center;
    position: relative;

    & > div {
      position: absolute;
      width: 100%;
      height: 4px;
      background: linear-gradient(139.24deg, #2F4FF1 2.88%, #4184F9 96.5%);
      box-shadow: 0px 1px 2px rgba(8, 236, 186, 0.283543);
    }
  }
}

.appointment-modal {
  & .react-datepicker-wrapper {
    width: 100%;
    cursor: pointer;
  }

  & .react-datepicker-time__input input {
    width: 116px !important;
  }
}

.react-datepicker-wrapper {
  width: calc(100% - 24px);
}

.text-input {
  width: 100%;
  padding-top: 14px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: solid 2px #9ba1ac;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 45px;
  display: flex;
  flex-wrap: wrap;

  & .suffix {
    align-self: flex-end;
    margin-bottom: 6px;
  }

  &.disabled {
    cursor: pointer;
  }

  &.clear {
    background: white;
    color: #20293d;

    & input {
      color: #20293d;
    }

    & label {
      color: #20293d;
    }
  }

  &::after {
    display: block;
    width: 0%;
    position: absolute;
    bottom: -2;
    left: 100%;
    transform: translateX(-100%);
    right: 0;
    background-color: #9ba1ac;
    height: 3px;
    content: "";
    transition: width 0.9s ease-in-out;
  }

  &:focus-within::after {
    transform: translateX(0%);
    width: 100%;
    left: 0;
    background-color: #2f4ff1;
    transition: width 0.9s ease-in-out;
  }

  & input {
    display: block;
    width: calc(100% - 24px);
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    background-color: transparent;
    border: 0;
    position: relative;
    color: #9ba1ac;
    padding-top: 15px;
    padding-bottom: 3px;
    z-index: 2;

    &:focus {
      outline: 0;
    }
  }

  & label {
    color: #9ba1ac;
    font-size: 15px;
    position: absolute;
    top: 39px;
    left: 0;
    line-height: 14px;
    transition: all 0.2s ease-in-out;
  }

  & input.has-input + label,
  & input:focus + label,
  &.has-input > label,
  &:focus-within > label {
    opacity: 0.6;
    color: #9ba1ac;
    font-size: 14px;
    transform-origin: 0 0;
    transform: translateY(-24px) scale(0.8);
    line-height: 16px;
    white-space: nowrap;
  }

  &.invalid::after {
    transform: translateX(0%);
    width: 100%;
    left: 0;
    background-color: red;
    transition: width 0.9s ease-in-out;
  }

  & .radio-group {
    position: unset;
    margin-right: 20px;

    & input {
      margin-right: 5px;
      display: inline;
      width: unset;
    }
  }

  &.simple {
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 0px;

    & input {
      padding-top: 0px;
      padding-bottom: 0px;
      width: 100%;
    }
  }
}

.text-input-search {
  width: 100%;
  padding-top: 14px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: solid 2px #9ba1ac;
  margin: 5px;
  max-height: 45px;

  &.noventa {
    width: 95% !important;
    color: black !important;
    font-size: 12px;
  }

  &::after {
    display: block;
    width: 0%;
    position: absolute;
    bottom: 25;
    transform: translateX(-100%);
    right: 20;
    background-color: #9ba1ac;
    height: 3px;
  }

  & input {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    background-color: transparent;
    border: 0;
    position: relative;
    color: #9ba1ac;
    padding-top: 15px;
    padding-bottom: 3px;

    &:focus {
      outline: 0;
    }
  }

  & label {
    color: #9ba1ac;
    font-size: 15px;
    position: absolute;
    top: 39px;
    left: 0;
    line-height: 14px;
    transition: all 0.2s ease-in-out;
  }

  & input.has-input + label,
  & input:focus + label {
    opacity: 0.6;
    color: #9ba1ac;
    font-size: 14px;
    transform-origin: 0 0;
    transform: translateY(-24px) scale(0.8);
    line-height: 16px;
  }
}

ul.autocomplete {
  display: block;
  background-color: #fff;
  z-index: 3;
  position: relative;
  box-shadow: 2px 3px 20px 0px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin: 0;
  margin-top: 3px;
  padding: 0;
  max-height: 210px;
  overflow-y: auto;

  &.hidden {
    display: none;
  }

  & li {
    list-style: none;
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background-color: #d9e5ec;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    &:first-child {
      border-radius: 10px 10px 0 0;
    }
  }
}

.paginator {
  display: flex;
  padding: 30px;
  padding-bottom: 10px;
  color: rgba(109, 118, 134, 1);

  & .counter {
    width: 20%;
    font-weight: bold;
    font-size: 18px;
    color: #98a0ae;
  }

  & .pages {
    font-size: 20px;
    width: 60%;
    justify-content: center;
  }

  & .number-elements {
    // width: 20%;
    font-size: 18px;

    & .select-input {
      width: 100px;
      padding-top: 0px;

      & select {
        padding-top: 0px;
        transform: translateY(-5px);

        :-internal-list-box option:checked {
          background-color: yellow !important;
        }
      }

      & .caret {
        top: 20%;
      }
    }
  }

  & > div {
    display: flex;
    height: 25px;

    div {
      margin: 0px 10px;
      cursor: pointer;

      &:first-child {
        margin-left: 25px;
      }

      &.extra-pages {
        &:hover {
          color: #6d7686;
          cursor: default;
          &:after {
            display: none;
          }
        }
      }

      &:hover {
        color: #aeb2bb;
      }

      &.selected {
        color: #fff;
        font-weight: bold;
      }

      &.selected:after,
      &:hover:after {
        content: " ";
        height: 4px;
        width: auto;
        border-radius: 2px;
        background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
        display: block;
      }
    }
  }
}

// .tab-selector-ribbon {
//   height: 60px;
//   width: 100%;
//   background-color: #1a202e;
//   box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
//   display: flex;
//   align-items: center;

//   &.control-ribbon {
//     border-radius: 12px 12px 0 0;
//   }

//   & .tab-selector-item {
//     margin: 16px;
//     opacity: 0.4;
//     color: #ffffff;
//     font-size: 16px;
//     line-height: 50px;
//     text-align: center;
//     box-sizing: border-box;
//     border-bottom: 6px transparent solid;
//     transition: 0.2s ease-in-out;
//     cursor: pointer;

//     &:hover {
//       opacity: 0.8;
//     }

//     &.selected {
//       opacity: 1;
//       border-bottom: 6px #3d77f7 solid;
//     }
//   }
// }

.trip-detail-modal {
  z-index: 9999;
  & h1 {
    display: flex;
    align-items: center;
  }
  & .select-input {
    padding-top: 0px;
    margin: 0 10px;
    width: 150px;
    & .label {
      color: #3f4f6c !important;
      font-size: 1.3rem !important;
      opacity: 1 !important;
    }
    & .caret {
      top: 50%;
    }
    & select {
      padding-top: 0px;
      z-index: 1;
    }
  }
}

.chip {
  width: fit-content;
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 6px;
  padding: 9px;
  margin: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.clear {
    color: #a4b4ce;
    border-color: #a4b4ce;
    transition: 0.4s ease-in-out;
  }

  &.full {
    color: #ffffff;
    background-color: #6988cd;
    transition: 0.4s ease-in-out;
  }

  &.white {
    background-color: #ebedf1;
    color: #3d5074;
  }

  &.small {
    font-size: 14px;
    line-height: 7px;
    padding: 6px;
    margin: 4px;
    border: 0px;
    height: 25px;
  }

  &.medium {
    font-size: 14px;
    line-height: 15px;
    padding: 6px;
    margin: 4px;
    border: 0px;
    height: 35px;
  }
}

.exceed-plus {
  display: flex;
  align-items: center;

  & .plus {
    color: #a4b4ce;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.limit {
    overflow: hidden,
  }
}

.text-exceed {
  // display: flex;
  position: relative;

  .text-exceed-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 90%;
    margin-right: 15px;
  }
  .plus {
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 12px;
    font-weight: bold;

    background-color: #a4b4ce;
    color: #fff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    text-align: center;
  }
}

// .navbar {
//   background-color: #576791;
//   z-index: 2;
//   height: 60px;
//   position: relative;

//   .back-icon {
//     position: absolute;
//     left: 40px;
//     top: 16px;
//     color: #afc8ff;
//     font-size: 20px;
//     font-weight: bold;
//     cursor: pointer;
//   }
// }

.collapser {
  position: relative;

  & .collapse-button {
    text-align: right;
    padding-bottom: 15px;

    & span {
      color: #afc8ff;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  & .collapse-content {
    border: 1px solid;
    max-height: 300px;
    transition: all 0.6s ease-in-out;
    overflow: auto;

    &.collapsed {
      // height: 0px;
      max-height: 0px !important;
      overflow: hidden;
      border-width: 0px;
      transition: all 0.6s ease-in-out;
    }
  }
}

.user-chip-container {
  max-height: 80px;
  overflow-y: auto;
}

.card {
  border-radius: 12px;
  color: #fff;
  background-color: #2f3b52;
  width: 24%;
  min-width: 268px;
  height: 185px;
  display: inline-block;
  position: relative;
  margin: 0.5%;
  transition: 0.5s;

  & .overlay {
    display: none;
  }

  &.fleet-card {
    padding: 10px;
    box-sizing: border-box;
    vertical-align: middle;

    & .top-row {
      display: flex;
      height: 60px;

      & .avatars {
        position: relative;
        width: 20%;

        & img {
          height: 48px;
          width: 48px;
          border-radius: 24px;

          &.company-logo {
            height: 26px;
            width: 26px;
            position: absolute;
            top: 48;
            left: 48;
            transform: translate(-60%, -60%);
          }
        }
      }

      & .credentials {
        width: 60%;
        padding-left: 10px;
        box-sizing: border-box;

        & p {
          margin: 2px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & .id {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 4px;
        }

        & .description {
          color: #a4b4ce;
          font-size: 12px;
        }

        & .company-name {
          font-size: 12px;
        }
      }

      & .gps-validation {
        width: 20%;
        position: relative;
        text-align: center;

        & .content {
          display: flex;
          padding: 0px;

          & .gps-badge {
            padding: 3px;
            padding-bottom: 1px;
            border-radius: 3px;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #2f3b52;
            background-color: rgb(170, 163, 163);

            &.active {
              background-color: #05c985;
            }
          }

          & .share-icon {
            height: 20px;
            width: 20px;
          }

          & .share-text {
            padding-left: 11px;
            font-size: 13px;
            align-self: flex-end;
          }
        }

        & .validated {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          font-weight: bold;

          &.Pendiente {
            color: #ff9800;
          }

          &.Validado {
            color: #05c985;
          }

          &.Rechazado {
            color: #ff4949;
          }

          &.Incompleto {
            color: #aaa3a3;
          }

          &.Vencido {
            color: #ff4949;
            // color: #e9c973;
          }
        }
      }
    }

    & .middle-row {
      height: 50px;
      display: flex;
      padding-bottom: 17px;
      border-bottom: 1px solid #444e63;

      & .validation-icon-container {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        & > div {
          height: 8px;
          width: 100%;
          border-radius: 10px;
          line-height: 17px;
          text-align: center;
          font-size: 10px;
          position: absolute;
          bottom: 10px;
          display: flex;
          justify-content: center;
          // transform: translateX(-50%);

          & > img {
            margin-top: 8px;
            height: 20px;
            width: 20px;
          }

          &.validated {
            img {
              margin-top: 10px;
              height: 17px;
              width: 17px;
            }
          }

          &.pending {
            img {
              height: 21px;
              width: 21px;
            }
          }

          &.rejected {
            img {
              height: 22px;
              width: 22px;
            }
          }

          // &.nodata {
          //   background-color: #aaa3a3;
          // }

          &.defeated {
            img {
              height: 21px;
              width: 21px;
            }
          }
        }
      }
    }

    & .bottom-row {
      height: 40px;
      padding: 10px;
      box-sizing: border-box;

      & span {
        color: #fff;
        background-color: #6988cd;
        border-radius: 4px;
        font-size: 12px;
        padding: 5px;
        margin: 3px;
        white-space: nowrap;
      }
    }
  }

  &:hover {
    background-color: #526a99;
    box-shadow: 10px 20px 40px 5px rgba(0, 0, 0, 0.6);
  }

  &.selection-on {
    cursor: pointer;
    transition: 0.3s ease;

    & .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 12px;
      background-color: rgba(32, 41, 61, 0.8);
      border: 1px solid #79a3ff;
      display: initial;

      & .checkbox {
        position: absolute;
        top: 14px;
        left: 18px;
        height: 33px;
        width: 33px;
        border-radius: 4px;
        background-color: #a7b6d6;
        transition: 0.3s ease;
      }
    }

    &.selected {
      & .checkbox {
        background-color: #3d77f7;

        &:after {
          content: "";
          height: 7px;
          width: 15px;
          border-bottom: 4px solid #fff;
          border-left: 4px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -69%) rotate(-45deg);
        }
      }
    }

    &:hover {
      transform: scale(1.03, 1.03);
    }
  }
}

.row {
  display: flex;
  position: relative;
  box-sizing: border-box;

  & .overlay {
    display: none;
  }

  &.selection-on,
  &.selection {
    cursor: pointer;
    transition: 0.3s ease;

    & .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 0px;
      background-color: rgba(32, 41, 61, 0.8);
      border: 1px solid #79a3ff;
      display: initial;
      box-sizing: border-box;

      & .checkbox {
        position: absolute;
        top: 14px;
        left: 18px;
        height: 33px;
        width: 33px;
        border-radius: 4px;
        background-color: #a7b6d6;
        transition: 0.3s ease;
      }
    }

    &.selected {
      & .checkbox {
        background-color: #3d77f7;

        &:after {
          content: "";
          height: 7px;
          width: 15px;
          border-bottom: 4px solid #fff;
          border-left: 4px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -69%) rotate(-45deg);
        }
      }
    }
  }

  &.fleet-row-header {
    height: 91px;
    background-color: #3d5074;
    display: flex;
    color: #a4b4ce;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px solid #a4b4ce;
    line-height: 91px;
    margin: 0 2%;
    border-radius: 12px 12px 0 0;

    & > div {
      text-align: center;
    }

    & .credentials {
      width: 30%;
    }

    & .normativity {
      width: 20%;
    }

    & .validated {
      width: 10%;
      padding: 25px 0;
      line-height: 20px;
    }

    & .tags {
      width: 15%;
    }

    & .operativeStatus {
      width: 15%;
    }

    & .gps {
      width: 10%;
    }

    & .rfc {
      width: 5%;
    }
  }

  &.fleet-row {
    color: #fff;
    height: 91px;
    background-color: #3d5074;

    &:hover {
      background-color: #526a99;
    }

    & + .fleet-row {
      border-top: 1px solid #a4b4ce;
    }

    & .avatars {
      width: 10%;
      padding: 20px;

      & img {
        height: 48px;
        width: 48px;
        border-radius: 24px;

        &.company-logo {
          height: 26px;
          width: 26px;
          position: absolute;
          top: 66;
          left: 66;
          transform: translate(-60%, -60%);
        }
      }
    }

    & .operativeStatus {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .rfc {
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .credentials {
      width: 20%;
      padding: 16px 0;

      & p {
        margin: 2px 0;
      }

      & .id {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      & .description {
        color: #a4b4ce;
        font-size: 12px;
      }

      & .company-name {
        font-size: 12px;
      }
    }

    & .validation {
      width: 10%;
      font-weight: bold;
      text-align: center;
      font-size: 14px;
      padding: 36px 0;

      &.Pendiente {
        color: #ff9800;
      }

      &.Validado {
        color: #05c985;
      }

      &.Rechazado {
        color: #ff4949;
      }

      &.Incompleto {
        color: #aaa3a3;
      }

      &.Vencido {
        color: #ff4949;
        // color: #e9c973;
      }
    }

    & .normativity {
      width: 20%;
      display: flex;
      padding: 24px 0;

      & .validation-icon-container {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        & > img {
          height: 25px;
        }

        & > div {
          height: 8px;
          width: 24px;
          border-radius: 10px;
          line-height: 17px;
          text-align: center;
          font-size: 10px;
          position: absolute;
          bottom: 10px;
          display: flex;
          justify-content: center;
          // transform: translateX(-50%);

          & > img {
            margin-top: 12px;
            height: 20px;
            width: 20px;
          }

          &.validated {
            img {
              margin-top: 14px;
              height: 17px;
              width: 17px;
            }
          }

          &.pending {
            img {
              height: 21px;
              width: 21px;
            }
          }

          &.rejected {
            img {
              height: 22px;
              width: 22px;
            }
          }

          // &.nodata {
          //   background-color: #aaa3a3;
          // }

          &.defeated {
            img {
              height: 21px;
              width: 21px;
            }
          }

          &.na {
            font-size: 14px;
            top: 39px;
          }
        }
      }
    }

    & .tags {
      max-width: 15%;
      width: 15%;
      padding: 24px 0;
      flex-grow: 1;

      & span {
        color: #fff;
        background-color: #6988cd;
        border-radius: 4px;
        font-size: 12px;
        padding: 5px;
        margin: 3px;
        white-space: nowrap;
      }
    }

    & .gps {
      width: 10%;
      text-align: center;

      & .content {
        display: flex;
        padding: 0px;
        justify-content: center;
        padding: 35px 0;

        & .gps-badge {
          padding: 3px;
          padding-bottom: 1px;
          border-radius: 3px;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #2f3b52;
          background-color: rgb(170, 163, 163);

          &.active {
            background-color: #05c985;
          }
        }

        & .share-icon {
          height: 20px;
          width: 20px;
        }

        & .share-text {
          padding-left: 11px;
          font-size: 13px;
          align-self: flex-end;
        }
      }
    }
  }

  & .tag .tag-body {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.facility-row > div,
  &.fleet-row > div {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.radio-options-container {
  display: flex;

  & .radio-container {
    font-size: 16px;
    letter-spacing: 0.33px;
    line-height: 25px;
    display: block;
    position: relative;
    margin: 0 12px 12px 12px;
    cursor: pointer;
    padding: 0px 35px;

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        & ~ .checkmark {
          background-color: #2196f3;

          &:after {
            display: block;
          }
        }
      }
    }

    & .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #ccc;
      border-radius: 50%;
      transition: 0.3s;

      &:after {
        content: " ";
        position: absolute;
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
        display: none;
      }
    }
  }
}

.company-chip {
  position: relative;
  background-color: #ebedf1;
  line-height: 32px;
  height: 50px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: normal;
  color: #3d5074;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 5px;

  & .avatar {
    margin-left: 8px;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  & .details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .text {
    height: 18px;
    display: flex;
    align-items: center;
  }

  & b {
    position: absolute;
    line-height: 20px;
    top: 16px;
    right: 10px;
    font-size: 12px;
    cursor: pointer;
    width: 9px;
    padding: 3px;
  }
}

.user-chip {
  position: relative;
  background-color: #ebedf1;
  display: inline-block;
  line-height: 32px;
  height: 28px;
  white-space: nowrap;
  margin: 4px;
  padding: 0 26px 0 5px;
  font-size: 14px;
  font-weight: normal;
  color: #3d5074;
  border-radius: 6px;

  & b {
    position: absolute;
    line-height: 20px;
    top: 5px;
    right: 5px;
    font-size: 12px;
    cursor: pointer;
    width: 9px;
    padding: 3px;
  }

  & .user-avatar {
    height: 23px;
    width: 23px;
    border-radius: 15px;
    margin-right: 6px;
    vertical-align: middle;
    transform: translateY(-2px);
  }

  & .avatar-container {
    height: 23px;
    width: 23px;
    border-radius: 20px;
    background-color: #fff;
    display: inline-block;
    margin-right: 7px;
    margin-top: 2px;
    vertical-align: middle;
    position: relative;
    transform: translateY(-2px);

    & img {
      height: 16px;
      width: 16px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// .tooltip {
//   visibility: hidden;
//   display: none;
//   position: absolute;
//   z-index: 1;
// }

.__react_component_tooltip.place-top.type-dark.eta-tooltip-content {
  width: auto;
}

.input-profile-image {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  // & .pictureImage {

  //   height: 194px;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   & .pictureFrame {
  //     background-image: url("/images/user_large.svg");
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     border: 2px dashed #5B90F7;
  //     border-radius: 5px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin: 0 auto;
  //     cursor: pointer;
  //     width: 170px;
  //     height: 170px;
  //     object-fit: cover;
  //     transition: transform 0.3s ease-in-out;
  //     transform: scale(1, 1);
  //   }

  //   & .pictureFrame:hover {
  //     transform: scale(1.1, 1.1);
  //   }

  // }

  // & .pictureImageCont {
  //   height: 60px;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   & .pictureButton {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  // }
}

/* Containers */

.panel {
  border-radius: 12px;
  background-color: #2f3b52;
  padding: 16px;
  margin-bottom: 20px;

  & > .title {
    color: #ffffff;
    font-size: 24px;
    line-height: 37px;
    display: flex;

    & > .number {
      margin-right: 15px;

      & > .border {
        background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
        width: 100%;
        height: 4px;
      }
    }
  }

  & .item {
    flex-basis: 100%;

    &.half_2 {
      flex-basis: 48%;
    }

    &.half {
      flex-basis: 50%;
    }

    &.third {
      flex-basis: 33%;
    }

    &.quarter {
      flex-basis: 25%;
    }
  }

  & .content {
    display: flex;
    flex-wrap: wrap;
    margin: 15px;
  }

  & .sub-panel {
    border-radius: 12px;
    background-color: #2f3b52;
    padding: 16px;
    margin-bottom: 20px;
    margin-left: -30px;
  }

  & .margin {
    margin-left: -60px;
  }

  
}

.check-container {
  font-size: 16px;
  letter-spacing: 0.33px;
  line-height: 25px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 0 12px 12px 12px;
  cursor: pointer;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #2196f3;

      &:after {
        display: block;
      }
    }
  }

  & .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border-radius: 50%;
    transition: 0.3s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}

.switch-container {
  width: 45px;
  height: 25px;
  border-radius: 30px;
  background-color: #657795;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &.checked {
    background-color: #008cff;

    &:after {
      transform: translateX(20px);
    }
  }

  &:after {
    display: block;
    top: 3px;
    left: 3px;
    width: 19px;
    height: 19px;
    border-radius: 24px;
    content: "";
    position: absolute;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
  }

  &.small {
    width: 30px;
    height: 15px;

    &:after {
      top: 2px;
      left: 2px;
      width: 11px;
      height: 11px;
    }

    &.checked {
      &:after {
        transform: translateX(15px);
      }
    }
  }
}

.chip-container {
  & .title {
    color: #afc8ff;
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
  }

  & .chips {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.tag-category-container {
  padding-bottom: 1px;

  & .title {
    height: 50px;
    line-height: 50px;
    border-bottom: 2px solid;
    font-size: 16px;
    font-weight: bold;
    color: #a4b4ce;
    position: relative;
  }

  & .tag {
    margin: 4px 10px;
  }

  & .tags-container {
    padding: 25px 0;
    margin-bottom: 24px;
    position: relative;

    & .selector {
      position: relative;

      & .toggle-select-button {
        position: absolute;
        right: 0px;
        top: -55px;
      }

      // & .items-container {
      //   // display: flex;
      // }
    }

    & .add-icon {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 25px;
      height: 25px;
      border-radius: 15px;
      background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      cursor: pointer;
    }
  }

  &:last-child {
    padding-bottom: 10px;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: #20293d;
  font-family: Overpass;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  padding-left: 26px;

  &.clear-color {
    color: #9ba1ac;
    font-size: 14px;
    width: fit-content;
  }

  &.gradient {
    & input:checked ~ .checkmark {
      background: linear-gradient(-60deg, #2A4397 0%, #3A72ED 10%, #D757D4 65%, #F67554 100%);
    }
  }

  &.inactive {
    opacity: 0.5;
    cursor: auto;
  }

  &.bold {
    color: #20293d;
    font-size: 14px;
    font-weight: bold;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #3d77f7;
      transition: 0.2s ease-in-out;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  & .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background-color: #a7b6d6;

    &:after {
      content: "✓";
      position: absolute;
      display: none;
      color: white;
      font-size: 20px;
      font-weight: bold;
      padding: 5px;
    }
  }

  // &:hover input ~ .checkmark {
  //   background-color: #ccc;
  // }

  /* Style the checkmark/indicator */
}

.table {
  & .header {
    display: flex;
    height: 53px;
    width: 100%;
    border-radius: 12px;
    background-color: #455471;

    & .header-item {
      margin: auto;
      height: auto;
      color: #afc8ff;
      font-size: 16px;
      line-height: 25px;
    }
  }

  & .row {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

/* Components */

.alert-row {
  color: #fff;

  & .alert-title {
    line-height: 95px;
    font-size: 20px;
    display: flex;
    position: relative;
    border-bottom: 1px solid #fff3;

    & > div {
      position: absolute;
      right: 0px;
      height: 100%;

      & .switch-container {
        position: absolute;
        left: -95px;
        top: 50%;
        transform: translateY(-50%);
      }

      & .collapser {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        & img {
          position: absolute;
          left: 24%;
          top: 32%;
          transform: rotate(180deg);
          transition: 0.4s ease;
        }

        &.collapsed img {
          transform: rotate(0deg) translateY(3px);
        }
      }
    }
  }

  & .notification-content {
    // max-height: 500px;
    transition: 0.8s ease-in-out;
    // overflow: hidden;
    overflow: visible;
    padding: 0 20px;
    position: relative;

    &.blocked {
      opacity: .5;
    }

    &.collapsed {
      max-height: 0px;
      overflow-y: hidden;
    }

    & .edit-btn {
      position: absolute;
      top: 20px;
      right: 10px;
      cursor: pointer;

      & img {
        height: 25px;
      }
    }

    & .notification-users {
      display: flex;
      width: 40%;
      // transform: translateY(-4px);
      position: relative;
      top: -4px;
      & .select-input {
        margin-top: 0;
        margin-bottom: 20px;
        // transform: translateY(-4px);
      }
    }

    & .action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;

      & .btn {
        margin-left: 20px;
      }
    }
  }

  & .alert-content {
    // max-height: 500px;
    transition: 0.8s ease-in-out;
    // overflow: hidden;
    overflow: visible;
    padding: 0 20px;

    &.collapsed {
      max-height: 0px;
      overflow-y: hidden;
    }

    & .alert-description {
      font-size: 12px;
      line-height: 24px;
    }

    & .alert-see-more {
      margin: 25px 0 40px 0px;

      & span {
        cursor: pointer;
        color: #79a3ff;
        font-size: 14px;
        font-weight: bold;
      }
    }

    & .alert-levels-container {
      padding-bottom: 10px;
    }

    & .alert-levels-intro {
      font-size: 16px;
      font-weight: bold;
    }

    & .alert-level {
      font-size: 14px;

      & .switch-container {
        position: relative;
        top: 6px;
        margin-right: 10px;
      }

      & button {
        margin: 0px 30px;
        display: initial;

        & img {
          margin: 0 7px 0 4px;
          top: 7px;
        }
      }

      & .alert-minutes {
        color: #a4b4ce;
        font-size: 12px;
      }

      & input {
        width: 35px;
        background-color: #212a3c;
        border: 0;
        border-bottom: 1px solid #fff;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        margin: 0 5px;
      }

      & img {
        margin-left: 15px;
        position: relative;
        top: 5px;
        cursor: pointer;
      }

      & + .alert-level {
        margin-top: 20px;
      }

      &.disabled {
        opacity: 0.4;

        & button,
        & input,
        & img {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    & .company-alert-group {
      padding-bottom: 10px;
      border-bottom: 1px solid #fff3;
      position: relative;

      &.blocked {
        opacity: .7;
      }

      // & .save-changes-button {
      //   margin-left: auto;
      // }

      & .action-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & .btn {
          margin-left: 20px;
        }
      }

      & .group-facility {
        width: 35%;
      }

      & .company-alert-level {
        display: flex;
        align-items: center;

        & .switch-container {
          margin-right: 10px;
        }

        & .alert-minutes {
          display: flex;
          align-items: center;
          margin-left: 15px;
          width: 30%;
          & .text-input {
            width: 20%;
            padding-top: 0;
          }

          & .select-input {
            width: 40%;
            padding-top: 0;
            margin-bottom: 20px;
          }
        }

        & .alert-users {
          display: flex;
          width: 40%;
          // transform: translateY(-4px);
          position: relative;
          top: -4px;
          & .select-input {
            margin-top: 0;
            margin-bottom: 20px;
            // transform: translateY(-4px);
          }
        }
      }

      & .edit-btn {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;

        & img {
          height: 25px;
        }
      }
    }

    & .add-button {
      color: #fff;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: linear-gradient(139.24deg, #2F4FF1 2.88%, #4184F9 96.5%);
      font-size: 35px;
      line-height: 44px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 10px;

      &.blocked {
        opacity: .7;
        cursor: not-allowed;
      }
    }
  }

  & + .alert-row {
    border-top: 1px solid;
  }
}

.fleet-normativity-row {
  & .header {
    background-color: #1a202e;
    border-radius: 12px 12px 0 0;
    color: #afc8ff;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    min-height: 60px;
    letter-spacing: 1px;
    // padding-left: 50px;
    display: flex;

    & .icon {
      width: 15%;
      align-self: center;
    }

    & .title {
      align-self: center;
      width: 60%;
      // line-height: 60px;
    }

    // & img {
    //   position: absolute;
    //   left: 25px;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    // }

    & .select-input {
      width: 25%;
      display: inline-block;
      // position: absolute;
      // right: 10px;
      padding-top: 0;

      & .label {
        top: 30px;
      }

      & .caret {
        top: 60%;
      }

      & select option:disabled {
        display: none;
      }
    }
  }

  & .content {
    background-color: #20293d;
    color: #ffffff;
    font-family: Overpass;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 50px;
    box-sizing: border-box;

    & .row {
      & + .row {
        margin-top: 20px;
      }

      & .subtitle {
        color: #d5deff;
        font-size: 14px;
        font-weight: normal;
        width: 40%;
      }

      & .value {
        width: 60%;
      }
    }
  }

  & + .fleet-normativity-row .header {
    border-radius: 0;
  }

  &:last-child {
    & .content {
      border-radius: 0 0 12px 12px;
    }
  }
}

.selection-footer {
  height: 80px;
  width: auto;
  right: 0;
  left: 0;
  position: fixed;
  bottom: 0;
  z-index: 3;
  background-color: #fff;
  padding: 25px 40px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  padding-right: 145px;

  & .__react_component_tooltip {
    &.show {
      box-shadow: none !important;
    }
  }

  & .action-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.hidden {
    display: none;
  }

  & .count-circle {
    display: inline-block;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    line-height: 34px;
    background-color: #3d77f7;
    color: #fff;
    text-align: center;
  }

  & .align-right {
    position: absolute;
    // right: 30px;
    top: 36px;
    right: 145;
  }

  & span {
    color: #0077e2;
    cursor: pointer;
    margin: 0 15px;
  }
}

.specification-card {
  height: fit-content;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  &.compact {
    & .container {
      padding: 0;

      & .content {
        margin: 0;

        & .column {
          margin: 0;
        }
      }
    }
  }

  & .unit-systems {
    display: flex;
    justify-content: flex-end;
  }

  & .container {
    height: auto;
    width: 100%;
    border-radius: 12px 12px 0 0;
    background-color: #1a202e;
    padding: 20px;
    box-sizing: border-box;

    & .title {
      color: #afc8ff;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
    }

    & .content {
      display: flex;
      justify-content: space-around;

      & label {
        color: #afc8ff;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        width: 100px;
      }

      & input {
        width: 70px;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 32px;
        background-color: transparent;
        border: none;
      }

      & .column {
        width: fit-content;
        margin: 5px;

        & .measures {
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          line-height: 25px;
          text-align: center;
        }

        & .measure-input {
          display: flex;
        }

        & .measure-block {
          text-align: start;

          & .input-block {
            width: 100%;
          }
        }

        & .input-block {
          display: flex;
          border-bottom: 2px solid #979797;
          width: 100px;

          & > label {
            width: 100%;
            text-align: end;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
            line-height: 32px;
          }
        }

        & .image {
          text-align: center;
        }
      }
    }
  }
}

.normativity-item {
  color: #ffffff;
  font-size: 16px;
  line-height: 25px;

  &.row {
    & > div {
      margin-top: auto;
      margin-bottom: auto;
    }

    & .inline-input {
      margin: 0;
      padding: 0;

      & .text-input-invalid {
        position: absolute;
        bottom: 0;
        transform: translateY(100%);

        & .p-error {
          margin: 0 0 0 10px;
        }
      }

      &.text-input {
        & label {
          top: 13px;
        }

        & input {
          padding-top: 0;

          &.has-input + label,
          &:focus + label {
            transform: translateY(-25px) scale(0.8);
          }
        }

        &.has-input > label,
        &:focus-within > label {
          transform: translateY(-25px) scale(0.8);
        }
      }

      // & .text-input input.has-input + label, 
      // & .text-input input:focus + label, 
      // & .text-input.has-input > label, 
      // & .text-input:focus-within > label {
      //   transform: translateY(-50px) scale(0.8);
      // }
    }
  }
}

.inline-input {
  margin: 0;
  padding: 0;

  & .text-input-invalid {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);

    & .p-error {
      margin: 0 0 0 10px;
    }
  }

  &.text-input {
    & label {
      top: 13px;
    }

    & input {
      padding-top: 0;

      &.has-input + label,
      &:focus + label {
        transform: translateY(-25px) scale(0.8);
      }
    }

    &.has-input > label,
    &:focus-within > label {
      transform: translateY(-25px) scale(0.8);
    }
  }

  // & .text-input input.has-input + label, 
  // & .text-input input:focus + label, 
  // & .text-input.has-input > label, 
  // & .text-input:focus-within > label {
  //   transform: translateY(-50px) scale(0.8);
  // }
}

.selector {
  & .toggle-select-button {
    text-align: right;
    padding-bottom: 15px;

    & span {
      color: #afc8ff;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.tag {
  display: inline-block;
  align-content: center;
  margin: 4px;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }

  & .tag-selector {
    position: relative;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background-color: #a7b6d6;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
    display: none;
    margin-right: 7px;
  }

  & .tag-body {
    // padding: 4px 10px;
    padding: 1px 4px;
    border-radius: 5px;
    border: 2px solid;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: #3d77f7;
    white-space: nowrap;

    &.short {
      max-width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.dark {
      color: #fff;
    }

    &.light {
      color: #000;
    }
  }

  &.selection-on {
    & .tag-selector {
      display: inline-block;
    }

    & > div {
      cursor: pointer;
    }

    &.selected {
      & .tag-selector {
        background-color: #3d77f7;

        &:after {
          content: "";
          height: 5px;
          width: 13px;
          border-bottom: 2px solid #fff;
          border-left: 2px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -69%) rotate(-45deg);
        }
      }
    }
  }
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  border-radius: 12px;
  box-shadow: 10px 20px 40px 5px rgba(0, 0, 0, 0.6);

  &.type-light.place-right:after {
    border-right-width: 11px;
  }

  &.place-right:after {
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    // left: -10px;
    left: -9px;
    top: 50%;
    // transform: translateY(-50%);
    transform: translateY(-36%);
  }

  & .tag {
    display: flex;

    & .tag-body {
      min-width: fit-content;
      max-width: fit-content;
    }
  }
}

.change-password-dialog {
  & .content {
    width: 700px;
    margin-left: -350px;
    background-image: url("/images/sent-email-decoration.png");

    & .custom-tooltip {
      background-color: rgb(40, 52, 71);
    }

    & .title {
      margin: 60px 60px 0 60px;
    }

    & .message {
      margin-top: 230px;
      margin-left: 50px;
      margin-right: 60px;
      margin-bottom: 10px;
      color: #20293d;
      line-height: 25px;
      text-align: center;
    }

    & .button_container {
      position: relative;
      bottom: 0px;
      right: 0px;
      margin-top: 30px;
      margin-bottom: 40px;
      margin-left: 500px;
      text-align: right;
    }
  }
}

.global-toast-container {
  background-color: rgba(0, 0, 0, 0);
  left: 30%;
  width: 60%;
  top: -4px;
  border-radius: 20px;
}

.orbi-selector {
  display: flex;
  position: relative;
  // z-index: 3;
  align-items: center;

  // .content {
  //   height: 70px;
  //   background-color: #242e42;
  //   display: flex;

  //   & .filter {
  //     width: 80%;
  //     height: 100%;
  //     display: flex;
  //     justify-content: space-evenly;

  //     & .list {
  //       height: 60%;
  //       display: flex;
  //       align-items: center;
  //       position: relative;
  //       margin: auto;

  //       &.filtered {
  //         background-color: #526a99;
  //         border-radius: 5px;
  //       }

  & .orbi-selector-title {
    font-size: 16px;
    line-height: 25px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    margin: 5px;
    display: flex;
    align-items: center;
  }

  & .orbi-selector-caret {
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
  }

  &.open {
    & .orbi-selector-caret {
      transform: rotate(180deg);
      transition: transform 0.5s ease-in-out;
    }

    & .orbi-selector-content {
      display: flex;
    }
  }

  & .orbi-selector-content {
    position: absolute;
    padding: 0;
    border: 1px solid #a4b4ce;
    box-sizing: border-box;
    top: 100%;
    left: 0;
    border-radius: 12px;
    width: auto;
    height: auto;
    flex-direction: column;
    background-color: white;
    z-index: 4;
    display: none;
    padding: 8px;
    overflow: hidden;

    & .simple-list {
      padding: 0;
      margin: 0 0 0 0;

      & .simple-list-item {
        margin: 5px;
        list-style-type: none;
        cursor: pointer;

        &:hover {
          background-color: #a4b4ce;
        }

        & .selected {
          border: solid 1px #a4b4ce;
          background-color: #a4b4ce;
        }
      }
    }

    // & .item {
    // }
  }

  .basic-error-message {
    color: #fb8a8a;
    margin-left: 10px;
  }

  //         & .separator {
  //           width: 100%;
  //           height: 0px;
  //           border: solid 0.5px #a4b4ce;
  //           margin-bottom: 5px;
  //         }

  //         & .search-list {
  //           width: 100%;

  //           & .text-input {
  //             width: 100%;
  //             margin: 0;
  //             top: -20px;
  //           }
  //         }

  //         & .checkmark {
  //           width: 20px;
  //           height: 20px;
  //         }

  //         & .items {
  //           width: 100%;
  //           // height: auto;
  //           display: flex;
  //           overflow-y: auto;
  //           overflow-x: hidden;
  //           max-height: 300px;

  //           &.chips {
  //             flex-wrap: wrap;
  //           }

  //           &.checks {
  //             flex-flow: column;
  //           }

  //           & .sub-item {
  //             margin-left: 15px;
  //           }
  //         }
  //       }
  //     }

  //     & .filter-button {
  //       align-self: center;

  //       & .btn {
  //         height: 32px;
  //         width: 80px;
  //       }
  //     }
  //   }

  //   & .search {
  //     height: 100%;
  //     width: 24%;
  //     display: inline-flex;
  //     padding: 0px 30px;
  //     box-sizing: border-box;
  //     position: relative;
  //     top: -10px;

  //     & .icono {
  //       display: block;
  //       position: absolute;
  //       bottom: 12;
  //       transform: translateX(-100%);
  //       right: 20;
  //     }
  // }
}

.mb-10 {
  margin-bottom: 10px;
}

.filter-bar-compact {
  & .fav{
    .preferences{
      display: flex;
    }
    .preferences:hover{
      background-color: #c9c9c9;
      box-shadow: 0 1px 3px 1.25px #c9c9c9;
    }
  }
  & .filter-content {
    & .filter {
      width: auto;
      & .list {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    & .btn {
      height: 25px;
      width: 100px;
      padding: 15px;
      margin: auto;
      margin-right: 5px;
    }
  }

  & .tags-container {
    max-height: 62px;
    overflow-y: auto;
    position: absolute;

    &.tagger {
      position: initial;
      align-self: center;
    }

    & .user-chip {
      background-color: #fff;
      height: 20px;
      line-height: 23px;

      & b {
        top: 1px;
      }
    }
  }

  & .email-input {
    display: block;
    // width: calc(100% - 24px);
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    background-color: transparent;
    border: 0;
    position: relative;
    color: #9ba1ac;
    // padding-top: 15px;
    // padding-bottom: 3px;
    // z-index: 2;
    border-bottom: 2px solid;
    // &:after {
    //   display: block;
    //   width: 0;
    //   position: absolute;
    //   bottom: -2;
    //   left: 100%;
    //   transform: translateX(-100%);
    //   right: 0;
    //   background-color: #9ba1ac;
    //   height: 3px;
    //   content: "";
    //   transition: width .9s ease-in-out;
    // }
    &:focus {
      outline: none;
      // &:after {
      //   transform: translateX(0);
      //   width: 100%;
      //   left: 0;
      //   background-color: #2f4ff1;
      //   transition: width .9s ease-in-out;
      // }
      // border-bottom: 2px solid #2f4ff1;
    }
    // &:focus-within {
    //   border-bottom: 2px solid #2f4ff1;
    // }
    &::placeholder {
      color: #9ba1ac;
    }
  }
}

// .assemble-table {
//   & .header {
//     height: 43px;
//     width: 100%;
//     border-radius: 0;
//     background-color: rgb(230, 235, 239);
//     & .header-item {
//       text-align: center;
//       color: #252325;
//       font-size: 14px;
//       font-weight: bold;
//       letter-spacing: 0;
//       line-height: 22px;
//     }
//   }
//   & .content {
//     display: none;
//   }
// }

.select-multiple-input {
  width: 100%;
  padding-top: 14px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: solid 2px #9ba1ac;
  margin: 5px;
  max-height: 45px;
  z-index: 3;

  &.invalid {
    transform: translateX(0%);
    width: 100%;
    left: 0;
    border-bottom: solid 2px red;
    // background-color: red;
    transition: width 0.9s ease-in-out;
  }

  // &.invalid::after {
  //   transform: translateX(0%);
  //   width: 100%;
  //   left: 0;
  //   background-color: red;
  //   transition: width 0.9s ease-in-out;
  // }

  & .label {
    color: #9ba1ac;
    font-size: 15px;
    position: absolute;
    top: 39px;
    left: 0;
    line-height: 14px;
    transition: all 0.2s ease-in-out;

    &.focused {
      opacity: 0.6;
      color: #9ba1ac;
      font-size: 14px;
      transform-origin: 0 0;
      transform: translateY(-24px) scale(0.8);
      line-height: 16px;
    }
  }
  & input {
    display: block;
    width: calc(100% - 24px);
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    background-color: transparent;
    border: 0;
    position: relative;
    color: #9ba1ac;
    // padding-top: 15px;
    padding-top: 15px;
    padding-bottom: 3px;
    z-index: 2;
  }

  &.open {
    & .caret {
      transform: rotate(180deg);
      transition: transform 0.5s ease-in-out;
    }

    & .select-multiple-input-content {
      display: block;
    }
  }

  & .caret {
    background: url(/images/select-arrow.svg) no-repeat;
    display: block;
    position: absolute;
    right: 0%;
    top: 70%;
    width: 20px;
    height: 12px;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
  }

  & .select-multiple-input-content {
    position: absolute;
    padding: 0;
    border: 1px solid #a4b4ce;
    box-sizing: border-box;
    // top: 70%;
    // left: 70%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    // border-radius: 12px;
    width: 100%;
    max-height: 430px;
    flex-wrap: wrap;
    background-color: white;
    z-index: 4;
    display: none;
    padding: 20px;
    overflow: hidden;
  }
}

.route-catalog-view {
  & .row.route-row-header {
    height: 91px;
    background-color: #3d5074;
    display: flex;
    color: #a4b4ce;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px solid #a4b4ce;
    line-height: 91px;
    margin: 0 2%;
    border-radius: 12px 12px 0 0;

    & > div {
      text-align: center;
    }

    & .route {
      width: 30%;
    }

    & .code {
      width: 10%;
    }

    & .alias {
      width: 15%;
    }

    & .classes {
      width: 10%;
    }

    & .tags {
      width: 20%;
    }

    & .tat {
      width: 10%;
    }

    & .caret {
      width: 5%;
    }
  }

  & .row.route-row {
    color: #fff;
    height: 91px;
    background-color: #3d5074;
    border-bottom: 1px solid rgba(164, 180, 206, 0.4);

    &:hover {
      background-color: #526a99;
      box-shadow: 0 5px 24px 10px #20293d;
    }

    & > div {
      overflow-x: auto;
      overflow-y: hidden;
    }

    & .overlay {
      & .checkbox {
        top: 26px !important;
      }
    }

    & .actions {
      width: 10%;
      padding: 20px;
      box-sizing: border-box;
    }

    & .route {
      width: 20%;
      padding: 16px 0;
      display: flex;
      align-items: center;
    }

    & .code {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .alias {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .classes {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .tags {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .tat {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .caret {
      width: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(0deg);
      transition: transform 0.5s ease-in-out;
      cursor: pointer;

      &.open {
        transform: rotate(180deg);
        transition: transform 0.5s ease-in-out;
      }
    }
  }

  & .route-row-child {
    display: none;
    background-color: #2f3b52;

    &.open {
      display: block;
    }

    & .route-row-child-header {
      color: #a4b4ce;
      height: 40px;
      border-bottom: 2px solid #a4b4ce;

      & > div {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .class {
        width: 10%;
      }

      & .distance {
        width: 15%;
      }

      & .config {
        width: 15%;
      }
    }

    & .route-row-child-body {
      color: #ffffff;
      height: 50px;
      border-bottom: 1px solid rgba(164, 180, 206, 0.2);

      &:hover {
        background-color: #526a99;
        box-shadow: 0 5px 24px 10px #20293d;
      }

      & > div {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .class {
        width: 10%;
      }

      & .distance {
        width: 15%;
      }

      & .config {
        width: 15%;
      }

      & .operators {
        width: 60%;
        flex-direction: column;

        & .operators_details {
          width: 100%;
          & > div {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.tooltip_operator_block {
  &.red {
    border: 2px solid red !important;
  }

  &.blue {
    border: 2px solid blue !important;
  }
}

.phone-number input[type=number]::-webkit-inner-spin-button, 
.phone-number input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.custom-date-picker {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  width: 300px; 
  position: absolute;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  z-index: 10;

  & .close-action {
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
  }

  & .react-datepicker {
    border-width: 0px;

    & .react-datepicker__header {
      background-color: white;
      border-bottom: 1px solid rgba(164, 180, 206, 1);

      & .react-datepicker__current-month {
        color: #20293d;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
      }
    }

    & .react-datepicker__month {
      border-bottom: 1px solid rgba(164, 180, 206, 1);
    }
  }

  & .dates {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    & input {
      height: 21px;
      width: 36px;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      text-align: center;
      margin: 0 5 0 5;
      border: none;
      border-bottom: 2px solid #20293d;
      outline: 0;
    }

    & .appointment-title {
      color: #20293d;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      display: flex;
    }

    & .appointment-date-active {
      color: rgba(61, 119, 247, 1);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      display: flex;
    }

    & .appointment-date {
      color: black;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 22px;
    }

    & .space {
      align-items: center;
      display: flex;

      color: #20293d;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }

  }

  & .content-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 10px;

    & button {
      min-width: 100px;
    }
  }

}


@media only screen and (max-width: 425px) {
  .register-new-client-co button.btn {
    min-width: 120px;
  }
}